export const ProvidersMobile = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M17.2223 0H2.77771C1.24435 0.00183105 0.00183105 1.24435 0 2.77771V17.2223C0.00183105 18.7556 1.24435 19.9982 2.77771 20H17.2223C18.7556 19.9982 19.9982 18.7556 20 17.2223V2.77771C19.9982 1.24435 18.7556 0.00183105 17.2223 0ZM18.8889 17.2223C18.8879 18.1422 18.1422 18.8879 17.2223 18.8889H2.77771C1.85776 18.8879 1.11206 18.1422 1.11115 17.2223V2.77771C1.11206 1.85776 1.85776 1.11206 2.77771 1.11115H17.2223C18.1422 1.11206 18.8879 1.85776 18.8889 2.77771V17.2223Z"
      fill="white"
    />
    <path
      d="M11.6667 10C11.6667 10.9204 10.9204 11.6667 10 11.6667C9.07959 11.6667 8.33328 10.9204 8.33328 10C8.33328 9.07959 9.07959 8.33328 10 8.33328C10.9204 8.33328 11.6667 9.07959 11.6667 10Z"
      fill="white"
    />
    <path
      d="M16.6667 15C16.6667 15.9204 15.9204 16.6667 15 16.6667C14.0796 16.6667 13.3333 15.9204 13.3333 15C13.3333 14.0796 14.0796 13.3333 15 13.3333C15.9204 13.3333 16.6667 14.0796 16.6667 15Z"
      fill="white"
    />
    <path
      d="M6.66672 15C6.66672 15.9204 5.92041 16.6667 5 16.6667C4.07959 16.6667 3.33328 15.9204 3.33328 15C3.33328 14.0796 4.07959 13.3333 5 13.3333C5.92041 13.3333 6.66672 14.0796 6.66672 15Z"
      fill="white"
    />
    <path
      d="M16.6667 5C16.6667 5.92041 15.9204 6.66672 15 6.66672C14.0796 6.66672 13.3333 5.92041 13.3333 5C13.3333 4.07959 14.0796 3.33328 15 3.33328C15.9204 3.33328 16.6667 4.07959 16.6667 5Z"
      fill="white"
    />
    <path
      d="M6.66672 5C6.66672 5.92041 5.92041 6.66672 5 6.66672C4.07959 6.66672 3.33328 5.92041 3.33328 5C3.33328 4.07959 4.07959 3.33328 5 3.33328C5.92041 3.33328 6.66672 4.07959 6.66672 5Z"
      fill="white"
    />
  </svg>
);
