/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useContext, useRef, useState } from 'react';
import {
  ModalMapStructure,
  ModalContextValue,
  OpenModalOptions,
  OpenedModal,
  ModalContextProviderProps,
  OpenModalFunction
} from './types';
import { prepareModals } from './utils';
import { useApiContext } from 'react-easyrocket';

const ModalContext = createContext<ModalContextValue<unknown, any>>({
  openModal: (name: unknown, options?: OpenModalOptions) => {},
  closeModal: () => {},
  openedModal: null,
  openedModalName: null,
  modalProps: null
});

export const useInitModal = <TypeofModalMap extends ModalMapStructure>(
  modalMap: TypeofModalMap
): ModalContextValue<TypeofModalMap> => {
  const { isAuthenticated } = useApiContext();

  const [openedModal, setOpenedModal] = useState<OpenedModal | null>(null);
  const modalsQueue = useRef<OpenedModal[]>([]);

  const openModal: OpenModalFunction<TypeofModalMap> = useCallback(
    (name, options) => {
      if (modalMap[name].authOnly && !isAuthenticated) return;
      setOpenedModal((currentlyOpenedModal) => {
        // если никакая модалка сейчас не открыта,
        // либо открытая модалка имеет такое же имя что и новая(при этом у новой указан replace в true) - сразу добавляем новую в стейт
        if (!currentlyOpenedModal || (currentlyOpenedModal?.name === name && options?.replace)) {
          return {
            name,
            options: {
              ...options,
              priority: options?.priority || modalMap[name].priority
            }
          };
        }
        // если какое-то модальное окно уже открыто - добавляем новое в очередь
        modalsQueue.current = prepareModals<TypeofModalMap>(name, {
          ...options,
          priority: options?.priority || modalMap[name]?.priority
        })(modalsQueue.current);

        return currentlyOpenedModal;
      });
    },
    [isAuthenticated, modalMap]
  );

  const closeModal = useCallback(() => {
    setOpenedModal(() => {
      if (modalsQueue.current.length) {
        const newActiveModal = modalsQueue.current[0];
        modalsQueue.current = modalsQueue.current.slice(1);
        return newActiveModal;
      }
      return null;
    });
  }, []);

  return {
    openModal,
    closeModal,
    openedModal: openedModal,
    openedModalName: openedModal?.name || null,
    modalProps: openedModal?.options?.props || null
  };
};

export const ModalContextProvider = <ModalMapType extends ModalMapStructure>({
  modalMap,
  children
}: ModalContextProviderProps<ModalMapType>) => {
  const contextValue = useInitModal(modalMap);
  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};

export function useUntypedModalContext<TypeofModalMap, ModalPropsType = unknown>() {
  const contextValue = useContext<ModalContextValue<unknown, ModalPropsType>>(ModalContext);
  return contextValue as ModalContextValue<TypeofModalMap, ModalPropsType>;
}
