import { useTranslation } from 'react-i18next';
import { Language, SupportedLanguages } from 'src/locale/types';
import { languageOptions } from 'src/locale/LanguagesAdjustable';
import { useApiContext, useUpdateInfo } from 'react-easyrocket';
import { useEffect } from 'react';

interface UseLanguageChangeOutput {
  handleChangeLanguage: (newLanguage: keyof SupportedLanguages) => void;
  language: keyof SupportedLanguages;
  currentLanguageIcon: Language['icon'];
  currentLanguageName: Language['labelName'];
}

export const useLanguageChange = (): UseLanguageChangeOutput => {
  const { i18n } = useTranslation();
  const { mutate: updateProfileLanguageInfo } = useUpdateInfo();
  const { isAuthenticated } = useApiContext();

  const language = i18n.language as keyof SupportedLanguages;
  const changeLanguage = (lng: keyof SupportedLanguages) => i18n.changeLanguage(lng);

  useEffect(() => {
    if (isAuthenticated) {
      updateProfileLanguageInfo({ language });
    }
  }, []);

  const handleChangeLanguage = (newLanguage: keyof SupportedLanguages): void => {
    if (newLanguage !== language) {
      changeLanguage(newLanguage)
        .then(() => {
          if (isAuthenticated) {
            updateProfileLanguageInfo({ language: newLanguage });
          }
        })
        .catch(() => changeLanguage(language));
    }
  };

  const { icon: currentLanguageIcon, labelName: currentLanguageName } = languageOptions[language];

  return {
    language: language,
    handleChangeLanguage,
    currentLanguageIcon,
    currentLanguageName
  };
};
