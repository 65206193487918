import { useGetAlternativeAuthMethods } from 'react-easyrocket';
import { SocialMediaLink } from './components/SocialMediaLink';
import { useTranslation } from 'react-i18next';
import { Skeletons } from '../Skeletons';
import { FC } from 'react';

interface AlternativeAuthMethodsBlockProps {
  isLogin?: boolean;
}

export const AlternativeAuthMethodsBlock: FC<AlternativeAuthMethodsBlockProps> = ({
  isLogin = false
}) => {
  const { t } = useTranslation();
  const { alternativeAuthMethods, isLoading } = useGetAlternativeAuthMethods();

  if (!isLoading && alternativeAuthMethods.every((item) => !item.is_enabled)) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center items-center w-full gap-2.5">
      {isLoading ? (
        <>
          <Skeletons className="w-full h-4 rounded-md" />
          <div className="w-full flex justify-center items-center gap-5">
            <Skeletons count={6} className="w-6 h-6 rounded-md" />
          </div>
        </>
      ) : (
        <>
          <p className="text-sm font-medium text-aamb_title">
            {t(isLogin ? 'alternativeAuth.titleLogIn' : 'alternativeAuth.titleSignUp')}
          </p>
          <div className="w-full flex justify-center items-center gap-5">
            {alternativeAuthMethods.map((method) => {
              if (!method.is_enabled) return null;

              return (
                <div key={method.name}>
                  {method.is_enabled && (
                    <SocialMediaLink name={method.name} path={method.oauth_url} />
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
