import dayjs from 'dayjs';
import { MouseEventHandler, forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';

interface ButtonProps {
  value: string;
  placeholder?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  displayedValueFormat?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ value, placeholder, onClick, displayedValueFormat }, ref) => {
    const currentValue = displayedValueFormat
      ? dayjs(value, 'YYYY-MM-DD').format(displayedValueFormat)
      : value;
    return (
      <button
        className={twJoin(
          'w-full bg-text-secondary border-solid px-5 flex items-center rounded-[20px] h-[42px] border outline-none text-left',
          !value && '!text-i_placeholder'
        )}
        onClick={onClick}
        ref={ref}
        type="button">
        {value ? currentValue : placeholder}
      </button>
    );
  }
);

Button.displayName = 'DatePicker_Button';
