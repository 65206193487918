import logo from 'src/assets/images/logoSidebar.webp';
import './styles.css';
import { useShortBalanceFormatted } from '../AccountWallet/Deposit';
import { getCurrencyByCountryCode, getCurrencyIcon } from 'src/utils';
import { Button } from 'src/components/Buttons/Button';
import { adventagesBonus, paymentsCards } from 'src/constants';
import landingBackgorundFirst from 'src/assets/images/backgroundBonusFirst.webp';
import landingBackgorundSecond from 'src/assets/images/backgroundBonusSecond.webp';
import landingBackgorundThird from 'src/assets/images/backgroundBonusThird.webp';
import { useEffect, useMemo, useState } from 'react';
import { makeFloat, useGetBonuses, useGetUserCountry } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { replaceWithEuroBigLogo } from 'src/EuroEvent/changeDefaultLogo';

const CHANGE_IMAGE_TIME = 5000;
export const MobileLanding = () => {
  const balance = useShortBalanceFormatted();
  const [currentImage, setCurrentImage] = useState(landingBackgorundFirst);
  const { openModal } = useModalContext();
  const { data: allBonuses } = useGetBonuses();
  const { userCountry } = useGetUserCountry();

  useEffect(() => {
    const updateImageInterval = setInterval(() => {
      setCurrentImage((prevImage) => {
        if (prevImage === landingBackgorundFirst) {
          return landingBackgorundSecond;
        } else if (prevImage === landingBackgorundSecond) {
          return landingBackgorundThird;
        } else {
          return landingBackgorundFirst;
        }
      });
    }, CHANGE_IMAGE_TIME);

    return () => clearInterval(updateImageInterval);
  }, []);

  const userCurrency = useMemo(() => {
    return getCurrencyByCountryCode(userCountry?.country);
  }, [userCountry?.country]);

  const bonusSum: number = allBonuses
    .filter((bonus) => bonus.tag === 'standard')
    .reduce<number>((preBonus, bonus) => {
      return preBonus + bonus.maxPayout[userCurrency];
    }, 0);

  return (
    <div className="sm:max-w-[350px] mx-auto">
      <div
        style={{
          backgroundImage: `url(${currentImage})`
        }}
        className="relative w-full h-[200px] bonus-card">
        <div className="absolute t-0 l-0 w-[100%] h-[100%] bgFilter" />
      </div>

      <div className="relative bg-white mx-[15px] rounded-xl flex flex-col items-center mt-[-30px] px-[10px]">
        <img
          src={replaceWithEuroBigLogo(logo)}
          className="max-w-[115px] mt-[11px] bm-[10px]"
          alt="jacktop logo"
        />

        <p className="uppercase text-[#FF0E1D] font-black leading-[90%] text-[40px] w-[300px] text-center mb-[10px]">
          Welcome pack
        </p>

        <p className="uppercase text-[#FF0E1D] font-semibold leading-[110%] text-[20px] w-[300px] text-center mb-[10px]">
          150% + 335 FREE SPINS
        </p>

        {!!bonusSum && (
          <p className="uppercase text-[#FF0E1D] font-black leading-[110%] text-[50px] w-[300px] text-center">
            {`${getCurrencyIcon(balance.currency)} ${makeFloat(bonusSum)}`}
          </p>
        )}

        <Button
          title="sign up"
          mode="button-secondary"
          className="w-full text-text-primary shadow-xl normal-case my-[15px]"
          onClick={() => {
            openModal('SIGNUP', { props: { hideWhyNot: true } });
          }}>
          sign up
        </Button>
      </div>

      <div className="flex justify-between my-[20px]">
        {adventagesBonus.map((advantage) => {
          return <IconLanding advantage={advantage} key={advantage.text} />;
        })}
      </div>

      <div className="flex flex-col self-center mx-[22px]">
        <div className="w-full border-t-2 border-secondary"></div>

        <div className="flex flex-wrap w-full my-[20px] justify-around items-center">
          {paymentsCards.map((element, index) => {
            return (
              <div key={index} className="landingImage">
                {element.image}
              </div>
            );
          })}
        </div>
        <div className="w-full border-t-2 border-secondary mb-[60px]"></div>
      </div>
    </div>
  );
};

export const IconLanding = ({ advantage }: { advantage: { text: string; icon: JSX.Element } }) => {
  return (
    <div className="gap-[5px] flex flex-col items-center rounded-none shadow-none w-full h-full before:content-none after:content-none">
      <div className="bg-il_bg w-[57px] h-[57px] jgf:w-[37px] jgf:h-[37px] rounded-[15px] flex items-center justify-center border-il_border">
        {advantage.icon}
      </div>
      <p className="text-il_text text-center text-[12px] leading-[90%] w-[70px] jgf:w-[50px]">
        {advantage.text}
      </p>
    </div>
  );
};
