import { FC } from 'react';
import video from '../../assets/video/video-preloader.webm';
import { Responsive } from '../ResponsiveNew/Responsive';
import { EVENT_IMAGES } from 'src/constants/eventImages';

export const VideoPreloader: FC = () => {
  return (
    <div className="h-screen flex justify-center items-center">
      <Responsive to={'sm'}>
        <img src={EVENT_IMAGES.LOGO.REGULAR} className="w-[174px] aspect-[174/140]" />
      </Responsive>
      <Responsive from={'sm'}>
        <video src={video} autoPlay muted loop controls={false} className="h-full"></video>
      </Responsive>
    </div>
  );
};
