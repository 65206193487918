import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSearch } from 'src/components/Inputs/InputSearch';
import { SimpleClose } from 'src/components/svg';
import searchImage from 'src/assets/images/searchOne.svg';

interface ProvidersSearchbarProps {
  searchValue: string;
  handleChangeProviders: (value: string) => void;
}

export const ProvidersSearchbar: FC<ProvidersSearchbarProps> = ({ searchValue, handleChangeProviders }) => {
  const { t } = useTranslation();
  return (
    <div className="w-[90%] my-7 z-[100]">
      <InputSearch
        placeholder={`${t('general.searchProvider')}`}
        value={searchValue}
        onChange={handleChangeProviders}
        leftIcon={<img src={searchImage} className="w-4 h-4" alt="input search icon" />}
        classNameInput="search-input text-i_search_text bg-i_search_bg w-full !pr-3.5 px-2.5 768:h-auto h-5 placeholder:text-[16px] text-[16px] 768:pl-0 pl-[3px] font-normal"
        className="flex flex-row items-center bg-i_search_bg w-full h-auto 768:py-[5px] border-b-2 border-b-i_search_border justify-around py-0"
        rightIcon={
          searchValue.length ? (
            <SimpleClose
              onShow={() => {
                handleChangeProviders('');
              }}
              className="w-[11px] h-[11px]"
            />
          ) : null
        }
      />
    </div>
  );
};
