import { useEffect } from 'react';
import { useGetUserCountry } from 'react-easyrocket';
import { useLocation } from 'react-router';
import { executeAdditionalGTMScript, getLanguageFromLS, getUserIdFromLS } from 'src/utils';
import { smarticoSetUserData } from 'src/utils/smartico';

export function useDoSomethingOnEveryPage() {
  const { pathname } = useLocation();
  const { refetch: refetchUserCountry } = useGetUserCountry();

  useEffect(() => {
    executeAdditionalGTMScript(getUserIdFromLS());
  }, [pathname]);

  useEffect(() => {
    smarticoSetUserData(getUserIdFromLS(), getLanguageFromLS());
  }, [pathname]);

  useEffect(() => {
    if (pathname.includes('games')) return;
    window.scrollTo({ top: 0 });
  }, [pathname]);

  useEffect(() => {
    refetchUserCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
}
