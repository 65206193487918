import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';
import './styles.css';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  labelClassName?: string;
};

export const Radio = ({ label, labelClassName, ...inputProps }: Props) => {
  return (
    <div className="flex items-center gap-2 relative">
      <input
        {...inputProps}
        type="radio"
        className="absolute w-full h-full opacity-0 z-10 cursor-pointer"
        readOnly
      />
      <label
        className={classNames(
          "text-[14px] text-text-secondary font-medium pl-[24px] after:content-[''] after:absolute after:left-0 after:min-w-[16px] after:h-[16px] after:rounded-full after:border before:content-[''] before:w-[8px] before:h-[8px] before:absolute before:rounded-full before:bg-[#ffffff] before:left-[4px] before:opacity-0 before:top-[4px]",
          labelClassName
        )}>
        {label}
      </label>
    </div>
  );
};
