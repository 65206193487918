import { FC } from 'react';
import { STORAGE_URL, useBoolean } from 'react-easyrocket';
import { twJoin } from 'tailwind-merge';

interface CardImageProps {
  showGameInfo: boolean;
  imageUrl: string;
}

export const CardImage: FC<CardImageProps> = ({ showGameInfo, imageUrl }) => {
  const [isLoaded, setLoaded] = useBoolean(false);

  return (
    <div
      className={twJoin(
        'h-full relative',
        showGameInfo ? 'rounded-t-[10px]' : 'rounded-[10px]',
        !isLoaded && 'gameCardImage'
      )}>
      <img
        className={twJoin(
          'h-auto w-full aspect-[29/40] z-10 relative',
          showGameInfo ? 'rounded-t-[10px]' : 'rounded-[10px]'
        )}
        onLoad={() => {
          setLoaded();
        }}
        src={`${STORAGE_URL}/storage/games/vertically${imageUrl}`}
        alt="game image"
        loading="lazy"
      />
    </div>
  );
};
