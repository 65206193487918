import { useCallback } from 'react';
import { Input } from 'src/components/Inputs/Input';
import { Button } from 'src/components/Buttons/Button';
import {
  useForm,
  FieldType,
  validateMaxLength,
  validateNumbersString,
  validatePhoneByCountryCode
} from 'src/hooks/useForm/index';
import { allCountriesData } from 'src/constants';
import { Autocomplete } from 'src/components/Autocomplete';
import { getArrayOfCountryPhoneCodes, getPhoneCodeByCountryCode } from 'src/utils';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useGetFullInfo, useUpdateInfo } from 'react-easyrocket';
import { resetDepositModalShow } from 'src/hooks/useOpenDepositOnSessionStart';
import { useModalContext } from 'src/hooks/useModalContext';
import { SignUpModalProps } from '..';

export type Values = {
  address: string;
  city: string;
  postcode: string;
  countryCode: string;
  phoneNumber: string;
};

export const StepThree = ({
  disableFields = false
}: {
  disableFields?: boolean;
}) => {
  const navigate = useNavigate();
  const { closeModal, modalProps } = useModalContext<SignUpModalProps>();

  const handleChangeStep = useCallback(() => {
    closeModal();
    if (modalProps?.onSignupSuccess) {
      modalProps.onSignupSuccess();
    } else if (!modalProps?.isSuccessfullNavigateDisabled) {
      navigate('/games/top-games');
    }
    resetDepositModalShow();
  }, [closeModal, modalProps, navigate]);

  const { mutateAsync, isLoading } = useUpdateInfo({ onSuccess: handleChangeStep });
  const { t } = useTranslation();

  const {
    values,
    errors,
    fieldsProps: { addressProps, cityProps, postcodeProps, countryCodeProps, phoneNumberProps },
    touched,
    setValues
  } = useForm<{
    address: FieldType.Text;
    city: FieldType.Text;
    postcode: FieldType.WithoutWildcards;
    countryCode: FieldType.Autocomplete;
    phoneNumber: FieldType.Number;
  }>({
    address: {
      placeholder: `${t('general.fullAddress')}`,
      fieldType: FieldType.Text,
      validation: [validateMaxLength(256)]
    },
    city: {
      placeholder: `${t('general.city')}`,
      fieldType: FieldType.Text,
      validation: [validateMaxLength(256)]
    },
    postcode: {
      placeholder: `${t('general.postCode')}`,
      fieldType: FieldType.WithoutWildcards,
      validation: [validateMaxLength(100)]
    },
    countryCode: {
      placeholder: `${t('general.selectCode')}`,
      fieldType: FieldType.Autocomplete,
      options: getArrayOfCountryPhoneCodes(allCountriesData),
      validation: [validateMaxLength(4)]
    },
    phoneNumber: {
      placeholder: `${t('general.phoneNumber')}`,
      fieldType: FieldType.Number,
      validation: [validateMaxLength(20), validateNumbersString, validatePhoneByCountryCode]
    }
  });
  const handleClick = useCallback(() => {
    mutateAsync({
      address: values.address ? values.address.replaceAll(' ', '') : undefined,
      postcode: values.postcode ? values.postcode.replaceAll(' ', '') : undefined,
      city: values.city ? values.city.replaceAll(' ', '') : undefined,
      code: values.countryCode !== '+' ? values.countryCode?.replace('+', '') : undefined,
      phone: values.phoneNumber ? values.phoneNumber.replaceAll(' ', '') : undefined
    });
  }, [
    mutateAsync,
    values.address,
    values.city,
    values.postcode,
    values.countryCode,
    values.phoneNumber
  ]);

  useGetFullInfo({
    onSuccess: (response) => {
      setValues({
        address: response.data.address,
        city: response.data.city,
        postcode: response.data.postcode,
        countryCode: getPhoneCodeByCountryCode(response.data.country),
        phoneNumber: response.data.phone.number
      });
    }
  });

  return (
    <form
      className="flex flex-col gap-5 items-center"
      onSubmit={(e) => e.preventDefault()}
      autoComplete="off">
      <div className="w-full">
        <Input {...addressProps} autoComplete="off" disabled={disableFields} />
        {touched.address && errors.address && (
          <span className="text-[red] text-xs">{t(errors.address)}</span>
        )}
      </div>
      <div className="flex w-full justify-center gap-3">
        <div>
          <Input {...cityProps} autoComplete="off" disabled={disableFields} />
          {touched.city && errors.city && (
            <span className="text-[red] text-xs">{t(errors.city)}</span>
          )}
        </div>
        <div>
          <Input {...postcodeProps} autoComplete="off" disabled={disableFields} />
          {touched.postcode && errors.postcode && (
            <span className="text-[red] text-xs">{t(errors.postcode)}</span>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex w-full justify-between gap-3">
          <div className="w-[35%] jlg:w-[30%] jxl:w-[30%] jxxl:w-[30%]">
            <Autocomplete
              notFoundText="registration.notFoundCode"
              {...countryCodeProps}
              maxLength={4}
              regexAllowedSymbols={/^\+?\d{1,3}/}
              disabled={disableFields}
              formatter={(value) => (value.indexOf('+') === -1 ? `+${value}` : value)}
            />
          </div>
          <div className="w-[65%] jlg:w-[70%] jxl:w-[70%] jxxl:w-[70%]">
            <Input disabled={disableFields} autoComplete="off" {...phoneNumberProps} />
          </div>
        </div>
        {touched.phoneNumber && errors.phoneNumber && (
          <span className="text-[red] text-xs pt-[10px]">{t(errors.phoneNumber)}</span>
        )}
      </div>
      <Button
        loading={isLoading}
        mode="button-secondary"
        onClick={handleClick}
        className="normal-case text-[14px]"
        title={`${t('registration.getAGift')}`}>
        {t('registration.getAGift')}
      </Button>
      <p className="w-full font-medium text-xs m-auto gap-2 text-center">
        <Trans
          i18nKey="registration.youCanAlsoFillInfo"
          components={{
            '1': <span className="text-[12px]" />,
            '2': (
              <Link
                to="account/profile/main"
                className="font-medium cursor-pointer text-color-link underline text-[12px]"
                onClick={closeModal}
                aria-label="profile main"
              />
            )
          }}
        />
      </p>
    </form>
  );
};
