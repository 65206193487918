import classNames from 'classnames';
import { FC, memo, useEffect, useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { SidebarArrow } from 'src/components/svg';
import { useMediaBreakpoint } from 'src/hooks/responsiveBreakpoints';
import { useDrawer } from 'src/hooks/useDrawer';
import { DesktopCollapsedSidebarContent } from './DesktopCollapsedSidebarContent';
import './DesktopSidebar.css';

// import logo from 'src/assets/images/logo.webp';
// import logoMini from 'src/assets/images/logoMini.webp';
import { useClickOutside } from 'src/hooks/useClickOutside';
import SidebarContent from './SidebarContent';
import { EVENT_IMAGES } from 'src/constants/eventImages';

const _DesktopSidebar: FC = () => {
  const { isLeftOpen, toggleLeft, openLeft, closeLeft } = useDrawer();
  const { xl } = useMediaBreakpoint();
  const desktopSidebarRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();

  const isAccountRoute = useMemo(() => pathname.includes('account'), [pathname]);

  useEffect(() => {
    // если открываем на очень широком экране, то сайдбар на десктопе по умолчанию открыт
    if (xl && !isLeftOpen && !isAccountRoute) {
      openLeft();
    }

    // если на очень широком экране переходим в личный кабинет - закрыть основной сайдбар
    if (isAccountRoute && isLeftOpen && xl) {
      closeLeft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xl, isAccountRoute]);

  useEffect(() => {
    // При переходах на другие страницы сайдбар на экранах <1280 автоматически закрывается
    // Добавлено, т.к. в некоторых ситуациях useClickOutside не срабатывает
    if (xl) return;
    closeLeft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, xl]);

  useClickOutside(desktopSidebarRef, () => {
    if (xl) return;
    if (isAccountRoute) return;
    closeLeft();
  });

  useEffect(() => {
    // закрывать сайдбар когда компонент анмаунтится - был баг, если с открытым сайдбаром зайти в игру, то
    // сайдбар на экране игры остается открытым
    return () => {
      closeLeft();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={desktopSidebarRef}
      className={classNames(
        'flex flex-col items-center transition-[width] duration-300 bg-background-sidebar py-6 text-white h-screen fixed left-0 top-0 z-[51]',
        isLeftOpen ? 'w-[270px]' : 'w-[70px]'
      )}>
      <div
        onClick={() => {
          toggleLeft?.();
        }}
        className={classNames(
          'cursor-pointer w-4/5 flex items-center mb-[30px] transition-transform duration-300',
          isLeftOpen ? 'justify-end' : 'justify-center'
        )}>
        <SidebarArrow className={isLeftOpen ? 'rotate-180' : ''} />
      </div>
      <Link to="/" aria-label="back to main" onClick={() => !xl && closeLeft()}>
        {isLeftOpen ? (
          <div className="max-w-[155px] mb-5">
            <img src={EVENT_IMAGES.LOGO.REGULAR} alt="jacktop logo" />
          </div>
        ) : (
          <img src={EVENT_IMAGES.LOGO.SMALL} alt="jacktop logo" className="max-w-[50px] mb-[30px]" />
        )}
      </Link>
      {isLeftOpen ? (
        <div className="w-full flex flex-col justify-start items-center overflow-y-scroll pl-[16px] pr-[10px] smooth-appearance sidebarScroll">
          <SidebarContent key="sidebar-content" />
        </div>
      ) : (
        <div className="w-full flex flex-col justify-start items-center overflow-auto">
          <DesktopCollapsedSidebarContent />
        </div>
      )}
    </div>
  );
};

export const DesktopSidebar = memo(_DesktopSidebar);
