import { FC, RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { Separator } from 'src/components/Separator/Separator';
import { AccountIcon, Download, LiveChatIcon } from 'src/components/svg';
import { useAddToHomescreenPrompt } from 'src/hooks/useAddToHomescreenPrompt';

import { useApiContext } from 'react-easyrocket';
import { sidebarLinks } from 'src/components/SidebarGamesDropdown/config';
import { CompactLanguageSelector } from 'src/components/LanguagesSelect/CompactLanguageSelector';
import { collapsedSidebarDataForTooltips } from './constants';
import { ProgressiveTooltipController } from '../ProgressiveTooltipController/ProgressiveTooltipController';
import { PROGRESSIVE_TOOLTIP_TYPE, useTooltip } from 'src/hooks/useProgressiveTooltip';
import { SidebarTooltip, Tooltips, useSidebarTooltip } from './components/SidebarTooltip';
// import PromoLink from '../../event/components/PromoLink/PromoLink';
import { AuthButtonsWithTooltips } from './components/AuthButtonsWithTooltips';
import { openChat } from '../LiveChatWidget/utils';

export const DesktopCollapsedSidebarContent: FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useApiContext();
  const navigate = useNavigate();
  const [onInstallPwaClick] = useAddToHomescreenPrompt();

  const buttonRef = useRef<HTMLDivElement | null>(null);
  const buttonRefSecond = useRef<HTMLDivElement | null>(null);
  const buttonRefThird = useRef<HTMLDivElement | null>(null);

  const refs = [buttonRef, buttonRefSecond, buttonRefThird];

  const { openTooltip, openedTooltip, closeTooltips } = useTooltip();
  const showTooltip = (ref: RefObject<HTMLDivElement>, tooltipType?: PROGRESSIVE_TOOLTIP_TYPE) => {
    if (openedTooltip !== undefined) {
      closeTooltips();
    } else {
      openTooltip(tooltipType, ref);
    }
  };

  const { handleActiveTooltip, clearTooltipState, activeTooltip } = useSidebarTooltip();

  return (
    <div className="h-screen flex flex-col justify-start items-center pb-[100px]">
      <div className="mb-[20px] gap-[10px] flex flex-col justify-center items-center hover:hover:text-yellow">
        {isAuthenticated ? (
          <SidebarTooltip
            id="account-icon"
            mouseEnterFunction={() => handleActiveTooltip(Tooltips.ACCOUNT)}
            mouseLeaveFunction={clearTooltipState}
            containerClickFunction={() => {
              navigate('/account/profile/main');
            }}
            showTooltip={activeTooltip === Tooltips.ACCOUNT}
            tooltipContent={t('general.account')}>
            <AccountIcon className="w-[30px] h-[30px] hover:stroke-yellow" />
          </SidebarTooltip>
        ) : (
          <AuthButtonsWithTooltips
            handleActiveTooltip={handleActiveTooltip}
            clearTooltipState={clearTooltipState}
            activeTooltip={activeTooltip}
          />
        )}
      </div>
      <Separator width="w-[60%]" noMarginTop />
      <div className="collapsed-container gap-[15px] my-[15px]">
        {collapsedSidebarDataForTooltips.map((item, index) => (
          <ProgressiveTooltipController
            key={item.title}
            controllerRef={refs[index]}
            icon={item.icon}
            onClick={() => showTooltip(refs[index], item.progressiveTooltipToOpen)}
            active={openedTooltip === item.progressiveTooltipToOpen}
          />
        ))}
      </div>
      <Separator width="w-[60%]" noMarginTop />
      <div className="my-5 flex flex-col items-center justify-center gap-3">
        {/* <PromoLink compact />
        <PromoLink compact shop /> */}
        {Object.values(sidebarLinks).map(({ path, icon, title, id, tooltip }) => (
          <SidebarTooltip
            key={path}
            id={id}
            mouseEnterFunction={() => handleActiveTooltip(tooltip)}
            mouseLeaveFunction={clearTooltipState}
            containerClickFunction={() => {
              navigate(path);
            }}
            showTooltip={activeTooltip === tooltip}
            tooltipContent={t(title)}>
            <NavLink
              className={({ isActive }) =>
                classNames(
                  '[&_>svg>path]:hover:fill-yellow',
                  isActive && '[&>svg>path]:fill-yellow'
                )
              }
              to={path}>
              {icon}
            </NavLink>
          </SidebarTooltip>
        ))}

        <SidebarTooltip
          id="support-icon"
          mouseEnterFunction={() => handleActiveTooltip(Tooltips.CHAT)}
          mouseLeaveFunction={clearTooltipState}
          containerClickFunction={openChat}
          showTooltip={activeTooltip === Tooltips.CHAT}
          tooltipContent={t('mainSidebarLinks.liveChat')}
          className="[&_>svg>path]:hover:fill-yellow">
          <LiveChatIcon />
        </SidebarTooltip>
      </div>
      <Separator width="w-[60%]" noMarginTop />
      <div className="my-5">
        <SidebarTooltip
          id="mobile-app-icon"
          mouseEnterFunction={() => handleActiveTooltip(Tooltips.DOWNLOAD)}
          mouseLeaveFunction={clearTooltipState}
          containerClickFunction={onInstallPwaClick}
          showTooltip={activeTooltip === Tooltips.DOWNLOAD}
          tooltipContent={t('general.installApp')}
          className="w-[50px] h-[36px]">
          <Download className="bg-button-primary w-full h-full py-[3px] hover:bg-button-primary-hover rounded-[5px]" />
        </SidebarTooltip>
      </div>
      <Separator width="w-[60%]" noMarginTop />
      <div className="my-5">
        <CompactLanguageSelector />
      </div>
    </div>
  );
};
