import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawer } from 'src/hooks/useDrawer';
import { InputSearch } from 'src/components/Inputs/InputSearch';
import { SearchIcon } from '../svg';
import NoGames from 'src/assets/images/noSearchGames.webp';
import { RecentSearches } from '../RecentSearches/RecentSearches';
import {
  useBackgroundScrollLock,
  useGetCombinedGames,
  useGetGames,
  useInfiniteScroll,
  useRecentSearches,
  useSearch
} from 'react-easyrocket';
import { MIN_SEARCH_VALUE_LENGTH } from 'src/constants';
import { GamesGridLegacy } from '../GamesGrid/GamesGridLegacy';
import CloseModal from '../ModalWrapper/CloseModal';
import { twJoin } from 'tailwind-merge';

export const TopbarSearch = () => {
  const { isTopSearchDrawerOpen, closeIsTopSearchDrawerOpenDrawer } = useDrawer();
  const { recentSearches, saveRecentResult, deleteRecentSearch } = useRecentSearches({
    storageKey: 'recentSearches',
    maxStoredSearchesLength: 20
  });
  const { value, onChange, debouncedValue, reset } = useSearch();
  const categoryId = '';

  useBackgroundScrollLock(isTopSearchDrawerOpen, [isTopSearchDrawerOpen]);

  const { t } = useTranslation();

  const enableRequest = Boolean(
    isTopSearchDrawerOpen && debouncedValue && debouncedValue.length >= MIN_SEARCH_VALUE_LENGTH
  );

  const { games: stubGames, isFetching: isFetchingStubGames } = useGetGames(
    { per_page: 9 },
    { target: 'topbarSearchRecommended', enabled: isTopSearchDrawerOpen }
  );

  const {
    allGames: games,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching
  } = useGetCombinedGames(
    { name: debouncedValue, per_page: 12 },
    { categoryId, enabled: enableRequest, target: 'topbarSearch' }
  );

  const handleSaveRecentResult = () => {
    const shouldSaveSearch =
      value.length >= MIN_SEARCH_VALUE_LENGTH && games.length && !recentSearches.includes(value);

    if (shouldSaveSearch) {
      saveRecentResult(value, true);
      sessionStorage.setItem('gameSearchValue', value);
    }
  };

  const { parentRef, childRef } = useInfiniteScroll({
    callback: () => {
      if (hasNextPage && value.length >= MIN_SEARCH_VALUE_LENGTH) {
        fetchNextPage();
      }
    }
  });

  useEffect(() => {
    onChange(sessionStorage.getItem('gameSearchValue') || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gamesFound = games.length > 0;
  const gamesNotFound =
    games.length === 0 && isTopSearchDrawerOpen && value.length !== 0 && isFetched;

  return (
    <div
      className={twJoin(
        'w-full flex flex-col h-screen bg-background-main fixed top-0 left-0 z-[700] p-[15px] transition-all overflow-y-auto',
        isTopSearchDrawerOpen ? 'translate-y-0' : 'translate-y-[-100%]'
      )}
      ref={parentRef}>
      <div className="w-full flex justify-end items-start">
        <CloseModal
          onClose={() => {
            closeIsTopSearchDrawerOpenDrawer();
            reset();
          }}
          className="flex-shrink-0 w-4 h-4"
        />
      </div>
      <InputSearch
        placeholder={t('general.searchGamePlaceholder')}
        value={value}
        onChange={onChange}
        leftIcon={<SearchIcon className="w-5 h-5" fill="#3C404A" />}
        className="flex bg-white items-center rounded-[20px] px-5 py-[13px] w-full my-2.5"
        classNameInput="text-[#3C404A] text-[16px] px-2.5 focus:outline-none w-full"
      />
      <RecentSearches
        onChange={onChange}
        recentSearches={recentSearches}
        deteleRecentSearch={deleteRecentSearch}
      />
      {isTopSearchDrawerOpen && gamesNotFound && (
        <>
          <div className="flex flex-col mx-auto items-center justify-center text-center max-w-[240px]">
            <p className="text-white m-auto font-bold">{t('gamesPage.noGamesInCategory')}</p>
            <img src={NoGames} alt="no games" className="w-full" />
          </div>
        </>
      )}
      {isTopSearchDrawerOpen && games.length === 0 && (
        <>
          <p className="pb-2.5 font-bold text-[18px] text-text-secondary mt-2.5">
            {t('general.recommended')}
          </p>

          {stubGames && (
            <div className="overflow-auto">
              <GamesGridLegacy
                isFetching={isFetchingStubGames}
                games={stubGames}
                className="mb-[110px]"
              />
            </div>
          )}
        </>
      )}
      {isTopSearchDrawerOpen && gamesFound && (
        <>
          <p className="pb-2.5 font-bold text-[18px] text-text-secondary mt-2.5">
            {t('general.searchResults')}
          </p>

          <div className="overflow-auto">
            <GamesGridLegacy
              isFetching={isFetching}
              className="pb-10"
              games={games}
              onGameClick={handleSaveRecentResult}
            />
            {!isFetching && hasNextPage && (
              <div className="h-5 mb-[70px] w-full" ref={childRef}></div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
