import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'src/components/Carousel/Carousel';
import { TournamentsCarouselCard } from './TournamentsCarouselCard';
import { useProviderTournamentNotification } from 'src/hooks/useProviderTournamentNotification';

export const TournamentsCarousel = () => {
  const tournaments = useProviderTournamentNotification();
  const { t } = useTranslation();

  const slides = useMemo(
    () =>
      tournaments.map((tournament) => (
        <TournamentsCarouselCard tournament={tournament} key={tournament.createdAt} />
      )),
    [tournaments]
  );

  return (
    <div className="w-full py-10 mt-5 relative bg-TOURNAMENTS_CAROUSEL bg-[length:150%] bg-center no-repeat pb-[15vw] mb-[-15vw]">
      <p className="font-bold text-[36px] text-tcar_text text-center z-[2] relative">
        {t('general.promoTournaments')}
      </p>
      <Carousel
        slides={slides}
        autoplay
        interval={4000}
        arrows
        slideClassname="w-[55%] 1024:w-2/5 1280:w-2/5"
        inactiveSlideClassname="pointer-events-none"
      />
    </div>
  );
};
