import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button';
import { Logo } from './Logo';
import { Burger } from './Burger';
import { useDrawer } from 'src/hooks/useDrawer';
import { getCurrencyIcon } from 'src/utils';
import { makeFloat, useApiContext, useGetFullBalance } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';

type Props = {
  ClosePage?: ReactNode;
  onBurgerClickCustom?: () => void;
};

export const Header = ({ ClosePage, onBurgerClickCustom }: Props) => {
  const { openModal } = useModalContext();
  const { isAuthenticated } = useApiContext();
  const { t } = useTranslation();
  const { closeBottomDrawer } = useDrawer();
  const { fullBalance, bonusAccountCasino } = useGetFullBalance();

  const balance = useMemo(() => {
    const currency = fullBalance?.currency ?? 'EUR';
    const fullBalanceAmount =
      fullBalance?.balance[currency as keyof typeof fullBalance.balance] ?? 0;
    const bonusAccountCasinoBalance = bonusAccountCasino?.balance ?? 0;
    const totalBalance = (fullBalanceAmount ?? 0) + (bonusAccountCasinoBalance ?? 0);

    return `${getCurrencyIcon(currency)} ${makeFloat(totalBalance)}`;
  }, [bonusAccountCasino?.balance, fullBalance]);

  return (
    <header className="flex justify-between w-full max-h-full px-[10px] py-[5px] z-[21] relative shadow-header bg-header_bg">
      <div className="flex items-center gap-[10px]">
        <Burger customOnClick={onBurgerClickCustom} />
        <Logo />
      </div>
      {isAuthenticated ? (
        <div className="flex flex-row w-[70%] items-center gap-4 justify-end ml-[19%] jsm:ml-[10%] pl-[0px]">
          <Button
            onClick={() => {
              openModal('POPUP_DEPOSIT');
              closeBottomDrawer?.();
            }}
            mode="button-secondary"
            className="w-[40%] text-text-primary jsm:w-auto jsm:text-sm jsm:h-[35px] jgf:w-auto max-w-[190px]"
            title={`${t('general.deposit')} ${balance}`}
            label={`${t('general.deposit')} ${balance}`}
          />
          {ClosePage}
        </div>
      ) : (
        <div className="flex flex-row w-[70%] justify-end gap-x-2.5 items-center">
          <Button
            title={`${t('general.logIn')}`}
            mode="button-transparent"
            className="!h-[35px] px-4 !max-w-[190px]"
            onClick={() => {
              openModal('LOGIN');
              closeBottomDrawer?.();
            }}>
            <p className="text-[14px] font-bold">{t('general.logIn')}</p>
          </Button>
          <Button
            mode="button-secondary"
            title={`${t('general.signUp')}`}
            onClick={() => {
              openModal('SIGNUP');
              closeBottomDrawer?.();
            }}
            className="shadow-xl !h-[35px] w-auto px-4 !max-w-[190px]">
            <p className="text-[14px] font-bold whitespace-nowrap">{t('general.signUp')}</p>
          </Button>
          {ClosePage}
        </div>
      )}
    </header>
  );
};
