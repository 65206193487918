import { FC, ReactNode, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

export enum Tooltips {
  NONE = 'NONE',
  ACCOUNT = 'ACCOUNT',
  SIGNUP = 'SIGNUP',
  LOGIN = 'LOGIN',
  GAMES = 'GAMES',
  JACKPOTS = 'JACKPOTS',
  LIVE = 'LIVE',
  PROMO = 'PROMO',
  TOURNAMENTS = 'TOURNAMENTS',
  VIP = 'VIP',
  DOWNLOAD = 'DOWNLOAD',
  CHAT = 'CHAT'
}

interface SidebarTooltipProps {
  id: string;
  mouseEnterFunction: () => void;
  mouseLeaveFunction: () => void;
  containerClickFunction?: () => void;
  children: ReactNode;
  showTooltip: boolean;
  tooltipContent: string;
  className?: string;
}

export const SidebarTooltip: FC<SidebarTooltipProps> = ({
  id,
  mouseEnterFunction,
  mouseLeaveFunction,
  containerClickFunction,
  children,
  showTooltip,
  tooltipContent,
  className
}) => {
  return (
    <>
      <div
        id={id}
        className={twMerge(
          'cursor-pointer hover:bg-[#2a1f63] w-[35px] h-[35px] flex justify-center items-center rounded-[5px]',
          className
        )}
        onMouseEnter={mouseEnterFunction}
        onMouseLeave={mouseLeaveFunction}
        onClick={containerClickFunction}>
        {children}
      </div>
      <Tooltip
        anchorSelect={`#${id}`}
        place="right"
        isOpen={showTooltip}
        noArrow
        opacity={1}
        style={{
          backgroundColor: '#2a1f63',
          color: 'white',
          borderRadius: '3px',
          fontSize: '18px',
          padding: '4px 10px'
        }}>
        {tooltipContent}
      </Tooltip>
    </>
  );
};

export function useSidebarTooltip() {
  const [activeTooltip, setActiveTooltip] = useState<Tooltips>(Tooltips.NONE);

  const handleActiveTooltip = (value: Tooltips): void => {
    setActiveTooltip(value);
  };

  const clearTooltipState = (): void => setActiveTooltip(Tooltips.NONE);

  return { activeTooltip, handleActiveTooltip, clearTooltipState };
}
