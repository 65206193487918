import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../Buttons/Button';
import { prepareGameUrl } from 'src/utils';
import {
  GameData,
  MESSAGE_STATUS,
  useApiContext,
  useGetFullInfo,
  useMessageContext
} from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { GameInfo } from './components/GameInfo';
import { GameBadges } from './components/GameBadges';
import { getJackpotOfGameIfParticipates, makeJackpotQueryParameter } from './utils';
import { getAccountInfoErrorMessage } from './utils/getAccountInfoErrorMessage';
import { twJoin } from 'tailwind-merge';
import { CardImage } from './components/CardImage';
import { LightGameBadges } from './components/LightGameBadges';

interface GameProps {
  game: GameData;
  isWagering?: boolean;
  className?: string;
  showGameInfo?: boolean;
  navigationState?: string;
  hideBadges?: boolean;
  onGameClick?: () => void;
  queryTitle?: string;
}

export const GameCard: FC<GameProps> = ({
  game,
  isWagering,
  className,
  showGameInfo = true,
  hideBadges,
  queryTitle,
  onGameClick
}) => {
  const { t } = useTranslation();
  const { fullInfo } = useGetFullInfo();
  const navigate = useNavigate();
  const { openMessage } = useMessageContext();
  const { openModal } = useModalContext();
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useApiContext();

  const queryParameters = useMemo(() => {
    return makeJackpotQueryParameter(getJackpotOfGameIfParticipates(game as GameData));
  }, [game]);

  if (!game.image) {
    return null;
  }

  const navigateToGame = ({ isDemo }: { isDemo: boolean }) => {
    const gameUrl = prepareGameUrl(game.id, game.name);
    const searchParam =
      queryParameters || (queryTitle ? `&jackpot=${queryTitle?.toLowerCase()}` : '');

    navigate(`${gameUrl}${isDemo ? '/demo' : ''}?${searchParam}`, {
      state: { history: `${pathname}${search}` }
    });
  };

  const handlePlayGameClick = () => {
    if (!isAuthenticated) {
      openModal('SIGNUP', { props: { hideWhyNot: true } });
      return;
    }

    const errorMessage = getAccountInfoErrorMessage(game.providerId, fullInfo);

    if (errorMessage) {
      openMessage?.(t(errorMessage), MESSAGE_STATUS.ERROR);
      return;
    }
    onGameClick?.();
    navigateToGame({ isDemo: false });
  };

  const handlePlayDemoGameClick = () => {
    navigateToGame({ isDemo: true });
    onGameClick?.();
  };

  return (
    <div
      className={twJoin(
        'game-card relative cursor-pointer flex flex-col justify-end h-auto',
        className
      )}>
      <CardImage showGameInfo={showGameInfo} imageUrl={game.image} />
      {hideBadges ? (
        <LightGameBadges game={game} />
      ) : (
        <GameBadges queryParameters={queryParameters} game={game} isWagering={isWagering} />
      )}

      {showGameInfo && <GameInfo providerName={game.providerName} gameName={game.name} />}

      <div
        className={twJoin(
          'z-20 controls flex flex-col justify-center gap-3 px-2.5 transition-[0.5s] shadow-game w-full h-full rounded-lg absolute top-0 left-0 bg-gradient-to-t from-gg_bg_hover_from to-gg_bg_hover_to'
        )}>
        <Button
          onClick={handlePlayGameClick}
          title={`${t('general.play')}`}
          mode="button-secondary"
          className="w-full p-1.5 h-auto leading-4">
          {t('general.play')}
        </Button>
        {game.hasDemo && (
          <Button
            onClick={handlePlayDemoGameClick}
            title={`${t('general.playDemo')}`}
            mode="button-transparent"
            className="w-full h-auto flex items-center justify-center font-bold p-1.5 leading-4">
            {t('general.playDemo')}
          </Button>
        )}
      </div>
    </div>
  );
};
