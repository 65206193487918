import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Buttons/Button';
import { Separator } from 'src/components/Separator/Separator';
import { AccountIcon, LiveChatIcon } from 'src/components/svg';
import { useDrawer } from 'src/hooks/useDrawer';

import {
  useApiContext,
  useDeviceDetect,
  useGetBonuses,
  useGetLocalTournaments,
  useGetProviderTournaments
} from 'react-easyrocket';
import { LanguagesSelect } from 'src/components/LanguagesSelect';
import { PWACards } from 'src/components/PWACards/PWACards';
import SidebarButton from 'src/components/SidebarButton';
import SidebarGamesDropdown from 'src/components/SidebarGamesDropdown';
import { sidebarLinks } from 'src/components/SidebarGamesDropdown/config';
import SidebarUserInfo from 'src/components/SidebarUserInfo';
import { sidebarBanners } from 'src/constants';
import { getRandomInt } from 'src/utils';
import { useModalContext } from 'src/hooks/useModalContext';
import { ModalMapKeys } from 'src/newUseModalContext/constants';
import {
  sidebarCasinoOption,
  sidebarJackpotOption,
  sidebarLiveOption
} from 'src/constants/gameCategories';
import { openChat } from 'src/components/LiveChatWidget/utils';
import { SidebarGameBannerArrow } from 'src/components/svg/SidebarGameBannerArrow';
// import PromoLink from 'src/event/components/PromoLink/PromoLink';
// import PromoLink from '../../event/components/PromoLink/PromoLink';

const bonusTypes = {
  0: 'Registration',
  3: 'PromoCode',
  5: 'Deposits',
  6: 'Periodic',
  7: 'IncreaseWinnings',
  8: 'ActivatedByLink'
};

const SidebarContent = () => {
  const { isAuthenticated } = useApiContext();
  const { openModal } = useModalContext();
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();
  const { closeLeft } = useDrawer();
  const { data: bonusesToSort } = useGetBonuses();
  const { tournaments: providerTournaments } = useGetProviderTournaments();
  const { tournaments: localTournaments } = useGetLocalTournaments();

  const handleOpenModal = useCallback(
    ({
      modalName,
      navigateToProfile = false
    }: {
      modalName?: ModalMapKeys;
      navigateToProfile?: boolean;
    }) => {
      if (navigateToProfile) {
        navigate?.('/account/profile/main');
        isMobile && closeLeft();
        return;
      }

      modalName && openModal(modalName);
      isMobile && closeLeft();
    },
    [openModal, closeLeft, navigate, isMobile]
  );

  const handleLiveChat = () => {
    openChat();
    isMobile && closeLeft();
  };

  const bonuses = useMemo(() => {
    const sortBonuses = bonusesToSort?.filter((bonus) => {
      if (
        bonus.type in bonusTypes &&
        bonus.fullDescription &&
        bonus.shortDescription &&
        bonus.imageBanner &&
        bonus.imageBackground
      ) {
        return true;
      }
      return false;
    });
    return sortBonuses;
  }, [bonusesToSort]);

  const tournamentsCount = useMemo(
    () =>
      providerTournaments || localTournaments?.active
        ? (providerTournaments?.length || 0) + (localTournaments?.active.length || 0)
        : 0,
    [localTournaments?.active, providerTournaments]
  );

  const sidebarButtons = useMemo(() => {
    if (isAuthenticated) {
      return (
        <div className="w-full flex flex-col items-center gap-[10px] pb-[10px]">
          <Button
            className="text-background-fivefold"
            mode="button-secondary"
            onClick={() =>
              handleOpenModal({
                modalName: 'POPUP_DEPOSIT'
              })
            }>
            {t('general.deposit')}
          </Button>
          <Button
            mode="button-transparent"
            onClick={() =>
              handleOpenModal({
                navigateToProfile: true
              })
            }
            leftIcon={<AccountIcon className="w-[26px] mr-[15px]" />}>
            <p>{t('general.account')}</p>
          </Button>
        </div>
      );
    }

    if (!isAuthenticated && isMobile) return null;

    return (
      <div className="w-full flex flex-col items-center gap-[10px] pb-[10px]">
        <Button
          className="!text-background-fivefold"
          mode="button-secondary"
          onClick={() =>
            handleOpenModal({
              modalName: 'SIGNUP'
            })
          }>
          {t('general.signUp')}
        </Button>
        <Button
          mode="button-transparent"
          onClick={() =>
            handleOpenModal({
              modalName: 'LOGIN'
            })
          }>
          {t('general.logIn')}
        </Button>
      </div>
    );
  }, [isAuthenticated, isMobile, handleOpenModal, t]);

  const { link, image, bannerTitle } = useMemo(() => {
    const numberBanner = getRandomInt(3);
    return sidebarBanners[numberBanner];
  }, []);

  return (
    <div className="w-full flex flex-col gap-[30px] items-center">
      {sidebarButtons}

      <SidebarUserInfo />

      <div className="w-full flex flex-col items-center gap-[20px]">
        <Separator width="w-full" noMarginTop />

        <SidebarGamesDropdown option={sidebarCasinoOption} />

        <SidebarGamesDropdown option={sidebarLiveOption} />

        <SidebarGamesDropdown option={sidebarJackpotOption} />

        <Separator width="w-full" noMarginTop />

        {/* <PromoLink />
        <PromoLink shop={true} /> */}

        <SidebarButton option={sidebarLinks.jackpots} />

        <SidebarButton count={bonuses.length} option={sidebarLinks.bonuses} />

        <SidebarButton count={tournamentsCount} option={sidebarLinks.tournaments} />

        <SidebarButton option={sidebarLinks.vip} />

        <Separator width="w-full" noMarginTop />

        <button
          onClick={handleLiveChat}
          className="w-full flex items-center gap-[8px] text-[18px] font-bold 768:hover:text-yellow 768:[&_>svg>path]:hover:fill-yellow">
          <LiveChatIcon />
          <p>{t('mainSidebarLinks.liveChat')}</p>
        </button>

        <Separator width="w-full" noMarginTop />
      </div>

      <div className="w-full flex flex-col gap-[20px]">
        <Link
          className="cursor-pointer relative hover:scale-[1.04] transition rounded-[15px] pr-3 pl-[30%] h-[70px] border border-solid border-secondary bg-cover bg-no-repeat flex justify-end items-center"
          to={link}
          onClick={() => {
            isMobile && closeLeft();
          }}
          aria-label="banner"
          style={{ backgroundImage: `url(${image})` }}>
          <div className="flex justify-center items-center">
            <p className="text-base text-white font-bold first-letter:uppercase leading-5 mr-3 max-w-[100px]">
              {t(bannerTitle)}
            </p>
            <SidebarGameBannerArrow />
          </div>
        </Link>

        <button
          className="cursor-pointer relative hover:scale-[1.04] transition bg-[url('/src/assets/images/driver.webp')] rounded-[15px] pr-8 h-[70px] border border-solid border-secondary bg-cover bg-center bg-no-repeat flex justify-end items-center"
          onClick={() => {
            if (!isAuthenticated) {
              openModal('SIGNUP');
              return;
            }
            navigate('/game/15347-Boomerang');
            isMobile && closeLeft();
          }}>
          <div className="max-w-[100px] text-left font-bold">
            <p className="text-base text-white leading-4">{t('general.driver')}</p>
            <p className="text-sm text-yellow lowercase">{t('general.boomerang')}</p>
          </div>
        </button>
      </div>

      <Separator width="w-full" noMarginTop />

      <PWACards />

      <LanguagesSelect containerClassname="max-w-full mt-0 w-full" />
    </div>
  );
};

export default memo(SidebarContent);
