import { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import './styles.css';
import classNames from 'classnames';

type Values = {
  description?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  name?: string;
  classNamesForDescription?: string;
};

export const Checkbox = ({
  description,
  onChange,
  checked = false,
  disabled,
  id,
  name,
  classNamesForDescription
}: Values) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setIsChecked(!isChecked);
      onChange?.(e);
    },
    [isChecked, onChange]
  );
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  return (
    <div className="flex gap-4">
      <div className="relative er-checkbox max-h-[15px] min-h-[15px] min-w-[15px] flex">
        <input
          type="checkbox"
          className="absolute z-20 opacity-0 cursor-pointer w-full h-full"
          id={id}
          name={name}
          checked={isChecked}
          disabled={disabled}
          onChange={handleChange}
        />
        <span
          className="rounded z-10 border bg-text-secondary border-input-border relative inline-block min-h-[15px] min-w-[15px]
        after:content-[''] after:w-full after:h-full after:absolute after:top-0 after:opacity-1 after:text-xs"></span>
      </div>
      <span className={classNames(classNamesForDescription)}>{description}</span>
    </div>
  );
};
