import { ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { GameCard } from '../GameCard';
import { BP } from 'src/constants';
import { GameData } from 'react-easyrocket';

const ROWS = 3;
export const TOTAL_GAMES = 24;

const getColumnsCountForScreen = (width: number): number => {
  if (width <= BP.gf) return 2;
  if (width >= BP.xl) return 8;
  if (width >= BP.lg) return 6;
  // пока добавляю через старые имена брейкпоинтов, позже создам задачу на переход на библиотечный GamesGrid
  if (width >= BP.md) return 5;
  if (width >= BP.sm) return 4;
  return 3;
};

export function useGamesGridConfig() {
  const [columns, setColumns] = useState<number>(getColumnsCountForScreen(window.innerWidth));

  const update = () => {
    setColumns(getColumnsCountForScreen(window.innerWidth));
  };

  useLayoutEffect(() => {
    update();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', update);
    return () => {
      window.removeEventListener('resize', update);
    };
  }, []);

  const totalGames = columns * ROWS;

  // -1 для кнопки See all games
  const gamesToShow = columns * ROWS - 1;

  return { columns, gamesToShow, totalGames };
}

/** @deprecated используйте GamesGrid */
export const GamesGridLegacy = ({
  isFetching,
  games,
  lastItem,
  className,
  onGameClick
}: {
  isFetching: boolean;
  games: GameData[];
  lastItem?: ReactNode;
  className?: string;
  onGameClick?: () => void;
}) => {
  const { gamesToShow } = useGamesGridConfig();

  return (
    <div
      className={classNames(
        className,
        'w-full grid grid-cols-3 gap-2 640:grid-cols-4 768:grid-cols-5 1024:grid-cols-6 1280:grid-cols-8 auto-rows-[1fr]'
      )}>
      {games.map((game) => {
        return <GameCard key={game.id} game={game} onGameClick={onGameClick} />;
      })}
      {isFetching && (
        <>
          {Array.from({ length: lastItem ? gamesToShow : gamesToShow + 1 }).map((_, i) => {
            return (
              <div key={i} className="bg-gg_bg rounded-[10px] h-auto animate-pulse">
                <div className="w-full h-auto aspect-[29/40]"></div>
                <div className="h-[36px] 768:h-[40px]"></div>
              </div>
            );
          })}
        </>
      )}
      {games.length !== 0 && lastItem}
    </div>
  );
};
