import { FC } from 'react';
import { Link } from 'react-router-dom';
import { passAffiliateParams } from 'react-easyrocket';
import { SOCIAL_ICONS } from '../config';
import { useTranslation } from 'react-i18next';

interface SocialMediaLinkProps {
  name: string;
  path: string;
}

export const SocialMediaLink: FC<SocialMediaLinkProps> = ({ name, path }) => {
  const { i18n: { language }} = useTranslation()
  const affiliateParams = passAffiliateParams();

  const url = new URL(path);

  const params = new URLSearchParams(url.search);

  params.append('lang', language);
  Object.entries(affiliateParams).forEach(([key, value]) => {
    params.append(key, value);
  });

  return (
    <Link
      referrerPolicy="origin"
      to={`${url.origin.toString()}${url.pathname.toString()}?${params.toString()}`}
      className="flex justify-center items-center w-6 h-6 cursor-pointer">
      <img src={SOCIAL_ICONS[name as keyof typeof SOCIAL_ICONS].source} />
    </Link>
  );
};
