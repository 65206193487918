import { useMessageContext } from 'react-easyrocket';
import { Message } from './Message';

export const MessagesContainer = () => {
  const { openedMessages } = useMessageContext();

  return (
    <div className="flex flex-col items-center fixed self-center z-[1000] top-[0px] w-fit right-[50%] translate-x-[50%] jsm:w-full">
      {openedMessages?.map((message) => (
        <Message
          key={message.id}
          id={message.id}
          text={message.text}
          status={message.status}
          closed={message.closed}
        />
      ))}
    </div>
  );
};
