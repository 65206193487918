import { FC, useMemo } from 'react';
import { prepareJackpotBadgeForGameCard } from 'src/utils';
import { GAMESOURCE, GameData, useGetJackpots } from 'react-easyrocket';
import { categoryBadgeGame } from 'src/constants';
import { useLocation } from 'react-router-dom';
import { isBjLeagueVisible } from 'src/utils/bjLeague';
import { DropsWinsBadge } from '../DropsWinsBadge';
import { DropsAndWinsBadge } from '../DropsAndWinsBadge';
import { LifeBadge } from '../LifeBadge';
import { BonusBadge } from '../BonusBadge';
import { BLACKJACK_LEAGUE, DROPS_AND_WINS_CATEGORY } from '../../constants';
import { JackpotBadge } from '../JackpotBadge';
import { FIFTEEN_SECONDS } from 'src/constants/time';

interface GameBadgesProps {
  queryParameters: string;
  game: GameData;
  isWagering?: boolean;
}

export const GameBadges: FC<GameBadgesProps> = ({ queryParameters, game, isWagering }) => {
  const { jackpots } = useGetJackpots(
    {},
    { staleTime: FIFTEEN_SECONDS, cacheTime: FIFTEEN_SECONDS }
  );
  const { pathname } = useLocation();

  const jackpotBadge = useMemo(
    () => prepareJackpotBadgeForGameCard(jackpots, queryParameters),
    [jackpots, queryParameters]
  );

  const badgeGame = useMemo(() => {
    if (game.categories && game.categories[0]?.id) {
      return categoryBadgeGame[game.categories[0]?.id as keyof typeof categoryBadgeGame];
    }

    if (pathname.includes('top-games')) {
      return categoryBadgeGame[2];
    }

    return null;
  }, [game.categories, pathname]);

  const isDropsAndWins = game.categories?.some((category) => {
    return category.id === DROPS_AND_WINS_CATEGORY;
  });

  const isBjLeague =
    isBjLeagueVisible() &&
    game.categories?.some((category) => {
      return category.id === BLACKJACK_LEAGUE;
    });

  const shouldShowJackpotBadge = jackpotBadge;
  const shouldShowLiveBadge = game.source === GAMESOURCE.CasinoLive;
  const shouldShowBonusBadge = game.isWagering || isWagering;
  const isJackpotBadge = shouldShowJackpotBadge && !isDropsAndWins;

  return (
    <>
      {isJackpotBadge && (
        <JackpotBadge
          jackpotBadge={jackpotBadge}
          shouldShowTitle={!isDropsAndWins && Boolean(isBjLeague)}
          badgeGame={badgeGame}
        />
      )}
      {isDropsAndWins ? <DropsWinsBadge /> : isBjLeague && <DropsAndWinsBadge />}
      {shouldShowLiveBadge && <LifeBadge />}
      {shouldShowBonusBadge && <BonusBadge />}
    </>
  );
};
