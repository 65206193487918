// * Logo imports
import logo from 'src/assets/images/logoSidebar.webp';
import smallLogo from 'src/assets/images/logoMini.webp';
import { replaceWithEuroBigLogo, replaceWithEuroMiniLogo } from 'src/EuroEvent/changeDefaultLogo';

export const EVENT_IMAGES = {
  LOGO: {
    REGULAR: replaceWithEuroBigLogo(logo),
    SMALL: replaceWithEuroMiniLogo(smallLogo)
  }
};
