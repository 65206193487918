import olympicBigLogo from './assets/images/olympic-logo-big.webp';
import olympicSmallLogo from './assets/images/olympic-logo-small.webp';
import { shouldShowOlympicLogo } from './dates';

const changeDefaultLogo = (newLogo: string, shouldChange: boolean) => (defaultLogo: string) => {
  if (shouldChange) return newLogo;
  return defaultLogo;
};

export const replaceWithEuroMiniLogo = changeDefaultLogo(olympicSmallLogo, shouldShowOlympicLogo);
export const replaceWithEuroBigLogo = changeDefaultLogo(olympicBigLogo, shouldShowOlympicLogo);
