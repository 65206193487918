import { FC } from 'react';

interface GameInfoProps {
  gameName?: string;
  providerName?: string;
}

export const GameInfo: FC<GameInfoProps> = ({ gameName, providerName }) => {
  return (
    <div className="bg-gg_game_info_bg px-2 py-[0.12rem] flex flex-col rounded-b-[10px] text-center h-9 768:h-10">
      <p className="text-gg_game_info_name text-xs 768:text-sm truncate">{gameName}</p>
      <p className="text-gg_game_info_provider_name text-xs truncate">{providerName}</p>
    </div>
  );
};
