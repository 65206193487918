import { useApiContext, useGetFullInfo } from 'react-easyrocket';
import { smarticoGo } from 'src/utils/smartico';

export function SmarticoButton() {
  const { isAuthenticated } = useApiContext();
  const { fullInfo } = useGetFullInfo();

  if (!isAuthenticated || !fullInfo) return null;
  if (!fullInfo.email.includes('__smartico.test')) return null;

  return (
    <button
      className="bg-[white] shadow-lg rounded-full p-4 fixed bottom-4 right-4 z-[500]"
      onClick={smarticoGo}>
      Open Missions
    </button>
  );
}
