import { useGameFrameAutoResize, useGetSportBrandId } from 'react-easyrocket';
import { useGetSportId } from './hooks/useGetSportId';
import { memo, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getSportUrl } from './utils/getSportUrl';
import { useFrameMessages } from './hooks/useFrameMessages';

export const SportSwissPage = memo(() => {
  const sportFrameRef = useRef<HTMLIFrameElement>(null);
  const { i18n } = useTranslation();

  const sportId = useGetSportId();
  const { data } = useGetSportBrandId({ 'sport-id': sportId }, {});

  const sportUrl = useMemo(() => {
    return getSportUrl(data?.data?.token, i18n.language);
  }, [data?.data?.token, i18n.language]);

  useFrameMessages(sportFrameRef.current);

  const { gameFrameStyle } = useGameFrameAutoResize({
    isHeaderVisible: true,
    headerHeight: 45
  });

  return (
    <iframe
      ref={sportFrameRef}
      className="w-full h-screen !mt-0"
      style={gameFrameStyle}
      src={sportUrl}
    />
  );
});

SportSwissPage.displayName = 'SportSwissPage';
