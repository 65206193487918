import { ReactNode } from 'react';
import { JackpotsIcon, PromoIcon, TournamentsIcon, VipIcon } from '../svg/SidebarIcons';
import { Tooltips } from '../Sidebar/components/SidebarTooltip';

export type SidebarLink = {
  id: string;
  icon: ReactNode;
  title: string;
  path: string;
  tooltip: Tooltips;
};

export const sidebarLinks: { [linkName: string]: SidebarLink } = {
  jackpots: {
    id: 'jackpots-icon',
    path: '/jackpots',
    icon: <JackpotsIcon />,
    title: 'menuItem.jackpots',
    tooltip: Tooltips.JACKPOTS
  },
  bonuses: {
    id: 'bonuses-icon',
    path: '/bonuses',
    icon: <PromoIcon />,
    title: 'menuItem.promo',
    tooltip: Tooltips.PROMO
  },
  tournaments: {
    id: 'tournaments-icon',
    path: '/tournaments',
    icon: <TournamentsIcon />,
    title: 'menuItem.tournaments',
    tooltip: Tooltips.TOURNAMENTS
  },
  vip: {
    id: 'vip-icon',
    path: '/vip',
    icon: <VipIcon />,
    title: 'menuItem.vip',
    tooltip: Tooltips.VIP
  }
};
