import { useTranslation } from 'react-i18next';

export const BonusBadge = () => {
  const { t } = useTranslation();
  return (
    <div className="flex absolute bottom-[60px] right-2 justify-center p-1.5 rounded-md w-max bg-gg_badge_bonus_bg font-bold text-xs z-20">
      {t('categoryBadgeGame.bonus')}
    </div>
  );
};
