import { GAME_CATEGORIES } from 'src/constants/gameCategories';
import { CasinoIcon, JackpotIcon } from '../svg/SidebarIcons';
import { AllLive } from '../svg/categories/AllLive';
import { PROGRESSIVE_TOOLTIP_TYPE } from 'src/hooks/useProgressiveTooltip';

export const collapsedSidebarDataForTooltips = [
  {
    title: 'gameCategories.casino',
    icon: <CasinoIcon />,
    progressiveTooltipToOpen: PROGRESSIVE_TOOLTIP_TYPE.CASINO
  },
  {
    title: 'general.allLiveGames',
    icon: <AllLive />,
    progressiveTooltipToOpen: PROGRESSIVE_TOOLTIP_TYPE.LIVE
  },
  {
    title: 'general.jackpotGames',
    icon: <JackpotIcon />,
    progressiveTooltipToOpen: PROGRESSIVE_TOOLTIP_TYPE.JACKPOTS
  }
];

export const progressiveTooltipsData = {
  /**
   * !если сюда добавляется новый объект, надо в компоненте DesktopCollapsedSidebarContent добавить новый ref для ProgressiveTooltipController
   * !в DesktopCollapsedSidebarContent есть коммент перед местом где надо докинуть ref
   */
  'gameCategories.casino': {
    title: 'gameCategories.casino',
    icon: <CasinoIcon />,
    childsData: [
      // GAME_CATEGORIES.easter,
      GAME_CATEGORIES.all,
      GAME_CATEGORIES.top,
      GAME_CATEGORIES.new,
      GAME_CATEGORIES.megaways,
      GAME_CATEGORIES['bonus-games'],
      GAME_CATEGORIES.dropwins,
      GAME_CATEGORIES.slots,
      GAME_CATEGORIES.fruits,
      GAME_CATEGORIES.books
    ]
  },
  'general.allLiveGames': {
    title: 'general.allLiveGames',
    icon: <AllLive />,
    childsData: [
      GAME_CATEGORIES['all-live'],
      GAME_CATEGORIES.blackjack,
      GAME_CATEGORIES.baccarat,
      GAME_CATEGORIES.poker,
      GAME_CATEGORIES.roulette
    ]
  },
  'general.jackpotGames': {
    title: 'general.jackpotGames',
    icon: <JackpotIcon />,
    childsData: [
      GAME_CATEGORIES['all-jackpots'],
      GAME_CATEGORIES['jackpot-mini'],
      GAME_CATEGORIES['jackpot-middle'],
      GAME_CATEGORIES['jackpot-grand']
    ]
  }
};
