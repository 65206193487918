import { ChangeEventHandler, FC } from 'react';
import { twJoin } from 'tailwind-merge';
import { PaymentFormField } from 'react-easyrocket';

interface BirthdayInputProps {
  field: PaymentFormField;
  value: string;
  birthdayValue: string;
  onBirthdayChange: ChangeEventHandler<HTMLInputElement>;
}

export const BirthdayInput: FC<BirthdayInputProps> = ({
  field,
  value,
  onBirthdayChange,
  birthdayValue
}) => {
  return (
    <>
      <div
        className="flex flex-col justify-between h-full items-start gap-2 w-full"
        key={field.name}>
        {field.title ? <label className={'text-base pl-5'}>{field.title}</label> : null}
        <div
          className={twJoin(
            'w-full relative border-solid rounded-[20px] px-5 py-3 border',
            'bg-text-secondary',
            'border-input-border',
            'formGeneratorDateInput'
          )}>
          <input
            className={'input w-full justify-between placeholder:text-[14px] z-[10000]'}
            value={value}
            onChange={onBirthdayChange}
            required
            type="date"
          />
        </div>
      </div>
      <input type="text" className="hidden" name={field.name} value={birthdayValue} />
    </>
  );
};
