import { useCallback, useEffect } from 'react';
import { Button } from 'src/components/Buttons/Button';
import { PasswordInput } from 'src/components/Inputs/PasswordInput';
import { Checkbox } from 'src/components';
import {
  FieldType,
  useForm,
  validateCountry,
  validateEmail,
  validateMaxLength,
  validatePassword,
  validateRequired
} from 'src/hooks/useForm/index';
import { Trans, useTranslation } from 'react-i18next';
import { Input } from 'src/components/Inputs/Input';
import { countriesOptions } from 'src/constants';
import { Autocomplete } from 'src/components/Autocomplete';
import { findCountryCodeByName, findCountryNameByCode } from 'src/utils';
import { usePasswordChecklist } from 'src/hooks/usePasswordChecklist';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  passAffiliateParams,
  useApiContext,
  useCurrency,
  useGetUserCountry,
  useRegistration,
  useSignUpModal
} from 'react-easyrocket';
import {
  resetDepositModalShow,
  setDepositModalAsShown
} from 'src/hooks/useOpenDepositOnSessionStart';
import { useModalContext } from 'src/hooks/useModalContext';
import { EVENT_LINKS } from 'src/event/config';
import { AlternativeAuthMethodsBlock } from 'src/components/AlternativeAuthMethods';
import { SignUpModalProps } from '..';

export type Values = {
  email: string;
  password: string;
  country: string | undefined;
  is18yers: boolean;
};

export const StepOne = ({
  changeStep,
  onRegistrationSuccess,
  disableFields = false
}: {
  changeStep?: (value: number) => void;
  onRegistrationSuccess?: () => void;
  disableFields?: boolean;
  isCompleted?: boolean;
  // showAlternativeMethods?: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLongModal } = useSignUpModal();
  const { isAuthenticated } = useApiContext();
  const { userCountry } = useGetUserCountry();
  const { currencyName } = useCurrency();
  const { openModal, modalProps, closeModal } = useModalContext<SignUpModalProps>();
  const { mutateAsync, isLoading } = useRegistration({
    onSuccess: () => {
      if (!modalProps?.onSignupSuccess) {
        if (location.pathname.includes(EVENT_LINKS.CATCH_GAME)) {
          closeModal?.();
        } else if (!modalProps?.isSuccessfullNavigateDisabled) {
          navigate('/games/top-games');
        }
      }
      onRegistrationSuccess?.();
      if (!isLongModal) {
        closeModal?.();
      }
    }
  });

  useEffect(() => {
    if (isLongModal !== undefined && isLongModal === true) {
      setDepositModalAsShown();
    }
    if (isLongModal !== undefined && isLongModal === false) {
      resetDepositModalShow();
    }
  }, [isLongModal]);

  const {
    values,
    errors,
    fieldsProps: { emailProps, passwordProps, countryProps, is18yearsProps },
    isFormValid,
    submitTried,
    setSubmitTried,
    updateDefaultStatePartially,
    focused
  } = useForm<{
    email: FieldType.Text;
    password: FieldType.Text;
    country: FieldType.Autocomplete;
    is18years: FieldType.Checkbox;
  }>({
    email: {
      placeholder: `${t('general.email')}`,
      fieldType: FieldType.Text,
      validation: [validateRequired, validateMaxLength(256), validateEmail]
    },
    password: {
      placeholder: `${t('general.password')}`,
      fieldType: FieldType.Text,
      validation: [validateRequired, validateMaxLength(64), validatePassword]
    },
    country: {
      fieldType: FieldType.Autocomplete,
      placeholder: `${t('general.chooseYourCoutry')}`,
      options: countriesOptions,
      validation: [validateRequired, validateCountry]
    },
    is18years: {
      fieldType: FieldType.Checkbox,
      validation: [validateRequired]
    }
  });

  useEffect(() => {
    if (!userCountry?.country) return;
    updateDefaultStatePartially({
      country: findCountryNameByCode(userCountry.country)
    });
  }, [userCountry?.country]);

  const handleClick = useCallback(async () => {
    setSubmitTried(true);
    if (!isFormValid) return;

    await mutateAsync({
      email: values.email,
      password: values.password,
      country: findCountryCodeByName(values.country),
      currency: userCountry?.currency[0] || currencyName,
      ...passAffiliateParams()
    });
  }, [
    setSubmitTried,
    isFormValid,
    values.email,
    values.password,
    values.country,
    mutateAsync,
    userCountry?.currency,
    currencyName
  ]);

  const passwordChecklist = usePasswordChecklist(values.password);

  return (
    <form
      className="flex flex-col gap-2 items-center"
      onSubmit={(e) => e.preventDefault()}
      autoComplete="off">
      <div className="w-full relative">
        <Input
          id="email-input"
          {...emailProps}
          autoComplete="off"
          disabled={disableFields}
          inputBorderColor={classNames(
            focused !== 'email' &&
              submitTried &&
              Boolean(errors.email) &&
              'border-input-border-error'
          )}
        />
        {focused !== 'email' && (
          <Tooltip
            anchorSelect="#email-input"
            className="bg-color-link text-bg-content-page rounded-4xl z-[1000] !max-w-full"
            place="top"
            isOpen={submitTried && Boolean(errors.email)}>
            <div>{errors.email}</div>
          </Tooltip>
        )}
      </div>
      <div className="w-full relative">
        <PasswordInput
          id="password-input"
          {...passwordProps}
          autoComplete="off"
          disabled={disableFields}
          inputBorderColor={classNames(
            focused !== 'password' &&
              submitTried &&
              Boolean(errors.password) &&
              'border-input-border-error'
          )}
        />
        {focused !== 'password' && (
          <Tooltip
            anchorSelect="#password-input"
            className="bg-color-link text-bg-content-page rounded-4xl z-[1000] !max-w-full"
            place="top"
            isOpen={submitTried && Boolean(errors.password)}>
            <div>{errors.password}</div>
          </Tooltip>
        )}
      </div>
      <div className="w-full relative flex items-center">
        <Tooltip
          anchorSelect="#password-input"
          className="bg-color-link self-center text-bg-content-page rounded-4xl z-[2000] jsm:!max-w-full"
          place="bottom"
          openOnClick>
          <div>
            {passwordChecklist.map((error) => (
              <p key={error.text} className={classNames({ 'line-through': error.done })}>
                {error.text}
              </p>
            ))}
          </div>
        </Tooltip>
        <div className="w-full" id="country-autocomplete">
          <Autocomplete
            notFoundText="registration.notFoundCountry"
            {...countryProps}
            regexAllowedSymbols={/[A-Za-z\- ]/g}
            disabled={disableFields}
            inputBorderColor={classNames(
              focused !== 'country' &&
                submitTried &&
                Boolean(errors.country) &&
                'border-input-border-error'
            )}
          />
          {focused !== 'country' && (
            <Tooltip
              anchorSelect="#country-autocomplete"
              className="bg-color-link text-bg-content-page rounded-4xl z-[1000]"
              place="top"
              isOpen={submitTried && Boolean(errors.country)}>
              <div>{errors.country}</div>
            </Tooltip>
          )}
        </div>
      </div>

      <div className="w-full relative">
        <Checkbox
          id="is18-checkbox"
          disabled={disableFields}
          classNamesForDescription="text-[14px] jlg:text-[14px] jxl:text-[14px] jxxl:text-[14px] font-medium"
          description={
            (
              <Trans
                i18nKey="registration.text"
                components={{
                  1: (
                    <Link
                      target="_blank"
                      to="/info_pages/terms_and_conditions"
                      className="font-medium cursor-pointer text-color-link underline"
                      aria-label="terms and conditions"
                    />
                  ),
                  2: (
                    <Link
                      target="_blank"
                      to="/info_pages/privacy_policy"
                      className="font-medium cursor-pointer text-color-link underline"
                      aria-label="privacy policy"
                    />
                  )
                }}
              />
            ) as unknown as string
          }
          {...is18yearsProps}
        />

        {submitTried && errors.is18years && (
          <Tooltip
            anchorSelect="#is18-checkbox"
            className="bg-color-link text-bg-content-page rounded-4xl z-[1000]"
            style={{ transform: 'translateZ(0)' }}
            place="bottom"
            isOpen={submitTried && Boolean(errors.is18years)}>
            <div>{errors.is18years}</div>
          </Tooltip>
        )}
      </div>
      <Button
        title={`${isAuthenticated ? t('registration.nextStep') : t('general.signUp')}`}
        loading={isLoading}
        mode="button-secondary"
        onClick={() => (isAuthenticated ? changeStep?.(1) : handleClick())}>
        {isAuthenticated ? t('registration.nextStep') : t('general.signUp')}
      </Button>
      <AlternativeAuthMethodsBlock />
      <p className="w-full font-medium text-xs m-auto flex justify-center gap-1 mt-[5px] mb-2.5">
        <span className="text-[14px]">{t('registration.alreadyHaveAccount')}</span>
        <span
          className="font-thin text-color-link text-[14px] underline cursor-pointer"
          onClick={() => {
            closeModal();
            openModal('LOGIN');
          }}>
          {t('registration.toLogin')}
        </span>
      </p>
    </form>
  );
};
