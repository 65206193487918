import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Arrow } from 'src/components/svg';
import classNames from 'classnames';
import { Input } from 'src/components/Inputs/Input';
import { PromoCode } from '../../../../components/svg/PromoCode';
import { useScrollIntoView } from 'react-easyrocket';

interface DepositPromoCodeProps {
  changePromoCode: (code: string) => void;
  label?: string;
  placeholder?: string;
  value: string;
  isModal: boolean;
}

export const DepositPromoCode = ({
  changePromoCode,
  value,
  label,
  placeholder,
  isModal
}: DepositPromoCodeProps) => {
  const { t } = useTranslation();
  const [showPromocode, setShowPromocode] = useState(false);

  const handleChangePromoCode = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      changePromoCode(event.target.value);
    },
    [changePromoCode]
  );

  useEffect(() => {
    setShowPromocode(!!value);
  }, [value]);

  const promocodeInputRef = useScrollIntoView({
    condition: showPromocode && isModal,
    effectDependencies: [showPromocode, isModal],
    scrollIntoViewParams: { behavior: 'smooth' }
  });

  const changeShowPromocode = () => {
    setShowPromocode((showPromocode) => !showPromocode);
  };

  return (
    <div className="flex flex-col justify-between gap-2.5 mb-5">
      <div
        className="flex gap-[12px] items-center cursor-pointer select-none"
        onClick={changeShowPromocode}>
        <PromoCode />
        <p className="font-bold text-base leading-[110%] text-dpc_title">{t('promoCode.title')}</p>
        <Arrow
          className={classNames('w-[20px] stroke-dpc_arrow', showPromocode ? 'rotate-180' : '')}
        />
      </div>
      {showPromocode ? (
        <div>
          {label ? <p className="ml-[15px] mb-2.5">{label}</p> : null}
          <div className="relative" ref={promocodeInputRef}>
            <Input placeholder={placeholder} value={value} onChange={handleChangePromoCode} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
