import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Providers } from '../Providers';
import { TopbarSearch } from '../TopbarSearch';
import classNames from 'classnames';
import { Footer, Navbar, ScrollToTopButton } from 'src/components';
import { Header } from 'src/components/Header/Header';
import { useDrawer } from 'src/hooks/useDrawer';
import { DesktopSidebar } from '../Sidebar/DesktopSidebar';
import { useBoolean, useWindowSize } from 'react-easyrocket';
import { AnimatePresence, motion } from 'framer-motion';

export const SportPageLayout: FC = () => {
  const { isLeftOpen } = useDrawer();
  const { isMobileLayout, isDekstopLayout } = useWindowSize();
  const [showUI, , , toggleShowUI] = useBoolean(false);

  return (
    <>
      {/* MOBILE LAYOUT */}
      {isMobileLayout && (
        <>
          <div className="header-container sticky top-0 z-[90]">
            <Header onBurgerClickCustom={toggleShowUI} />
          </div>
          <Outlet key="outlet" />
          <AnimatePresence>
            {showUI && (
              <>
                <Footer key="footer" />
                <motion.div
                  initial={{ transform: 'translateY(-35px)', opacity: 0 }}
                  animate={{ transform: 'translateY(0px)', opacity: 1 }}
                  transition={{
                    duration: 0.3
                  }}
                  exit={{ transform: 'translateY(-35px)', opacity: 0 }}
                  key="navbar-wrapper"
                  className="fixed bottom-0 right-0 left-0 z-[99]">
                  <Navbar />
                </motion.div>
              </>
            )}
          </AnimatePresence>
          <TopbarSearch />
          <Providers />
        </>
      )}
      {/* DEKSTOP LAYOUT */}
      {isDekstopLayout && (
        <div
          className={classNames(
            'max-w-[100%] transition-[padding] duration-300 pl-0 md:pl-[70px] mx-auto',
            isLeftOpen ? 'xl:pl-[270px]' : 'xl:pl-[70px]'
          )}>
          <DesktopSidebar />
          <Outlet key="outlet" />
          <Footer key="footer" />
        </div>
      )}
      <ScrollToTopButton />
    </>
  );
};
