import { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Providers } from '../Providers';
import { TopbarSearch } from '../TopbarSearch';
import classNames from 'classnames';
import { Footer, Navbar, ScrollToTopButton, Sidebar } from 'src/components';
import { Header } from 'src/components/Header/Header';
import { useDrawer } from 'src/hooks/useDrawer';
import { DesktopSidebar } from '../Sidebar/DesktopSidebar';
import { PwaPopUp } from '../PwaPopUp';
import { useFullScreen } from 'src/hooks/useFullScreen';
import { useWindowSize } from 'react-easyrocket';

export const Layout: FC = () => {
  const { isLeftOpen } = useDrawer();
  const { isMobileLayout, isDekstopLayout } = useWindowSize();
  const { isHideHUD } = useFullScreen();
  const { pathname } = useLocation();

  return (
    <>
      {/* MOBILE LAYOUT */}
      {isMobileLayout && (
        <>
          <div className="header-container sticky top-0 z-[90]">
            <PwaPopUp />
            {!isHideHUD && <Header />}
          </div>
          <Outlet key="outlet" />
          <Sidebar />
          <Footer key="footer" />
          <div className="fixed bottom-0 right-0 left-0 z-[99]">
            {!isHideHUD && pathname !== '/sport' && <Navbar />}
          </div>
          <TopbarSearch />
          <Providers />
          {/* DISABLE FIRST DEPOSIT BONUS FOR HALLOWEEN WEEK */}
          {/* <FirstDepositBonusBanner /> */}
        </>
      )}
      {/* DEKSTOP LAYOUT */}
      {isDekstopLayout && (
        <div
          className={classNames(
            'max-w-[100%] transition-[padding] duration-300 pl-0 md:pl-[70px] mx-auto',
            isLeftOpen ? 'xl:pl-[270px]' : 'xl:pl-[70px]'
          )}>
          <DesktopSidebar />
          <Outlet key="outlet" />
          <Footer key="footer" />
        </div>
      )}
      <ScrollToTopButton />
    </>
  );
};
