import { GameCard } from '../GameCard';
import { GamesGridConfigurable } from 'react-easyrocket';
import { SkeletonOfAnySize } from '../Skeletons';

export const TOTAL_GAMES = 24;

export const GamesGrid = GamesGridConfigurable({
  renderGameCard: ({ game, onGameClick, key }) => (
    <GameCard key={key} onGameClick={onGameClick} game={game} />
  ),
  GameSkeletonComponent: () => (
    <SkeletonOfAnySize>
      <div className="w-full h-auto aspect-[29/40]" />
      <div className="h-9 768:h-10" />
    </SkeletonOfAnySize>
  )
});
