import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useDrawer } from '../../hooks/useDrawer';
import classNames from 'classnames';
import { useGetAllProvidersCached } from 'src/hooks/useGetAllProvidersCached';
import { ProvidersSearchbar } from './components/ProvidersSearchbar';
import { ProvidersSearchResults } from './components/ProvidersSearchResults';

export const Providers = () => {
  const providersRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const { isBottomDrawerOpen, closeBottomDrawer } = useDrawer();
  const handleChangeProviders = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const {
    providers: allProviders,
    isFetching,
    isFetched
  } = useGetAllProvidersCached({ enabled: isBottomDrawerOpen });

  useEffect(() => {
    const resizeHandler = () => {
      if (isBottomDrawerOpen && window.innerWidth > 740) {
        closeBottomDrawer?.();
      }
    };
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [closeBottomDrawer, isBottomDrawerOpen]);

  const providersToRender = useMemo(
    () =>
      allProviders?.filter((provider) =>
        provider.name.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [searchValue, allProviders]
  );

  const handleClear = () => {
    setSearchValue('');
  };

  useClickOutside(providersRef, handleClear);

  return (
    <div
      className={classNames(
        'flex flex-col items-center bg-gcp_providers_bg mt-11 h-[360px] pb-[80px] z-[98] ease-in-out duration-300',
        isBottomDrawerOpen ? 'providers-list-opened' : 'providers-list'
      )}
      style={{
        transform: isBottomDrawerOpen ? 'translateY(0)' : 'translateY(100%)'
      }}
      ref={providersRef}>
      {isBottomDrawerOpen && (
        <>
          <ProvidersSearchbar
            searchValue={searchValue}
            handleChangeProviders={handleChangeProviders}
          />
          <ProvidersSearchResults
            isFetched={isFetched}
            isFetching={isFetching}
            providersToRender={providersToRender}
          />
        </>
      )}
    </div>
  );
};
