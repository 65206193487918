import { MESSAGE_STATUS, useApiContext, useMessageContext } from 'react-easyrocket';
import { Navigate, useSearchParams } from 'react-router-dom';

export function SocialSignUpHandler() {
  const { saveSession } = useApiContext();
  const [searchParams] = useSearchParams();
  const { openMessage } = useMessageContext();

  const status = searchParams.get('status');
  const message = searchParams.get('message');
  const uuid = searchParams.get('session_uuid');

  status === 'error' ? openMessage?.(message || '', MESSAGE_STATUS.ERROR) : saveSession(uuid);
  return <Navigate to={'/'} />;
}
