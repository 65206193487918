import classNames from 'classnames';
import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  htmlType?: string;
  label?: ReactNode;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  overrideContainerClassName?: string;
  disabled?: boolean;
  labelWithPadding?: boolean;
  labelClassname?: string;
  inputBorderColor?: string;
  containerClassName?: string;
  arrowClassName?: string;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function _Input(
  {
    htmlType,
    label,
    rightIcon,
    leftIcon,
    className,
    inputBorderColor,
    overrideContainerClassName,
    onChange,
    disabled,
    labelWithPadding,
    labelClassname,
    containerClassName,
    arrowClassName,
    ...inputProps
  },
  ref
) {
  return (
    <div
      className={
        overrideContainerClassName || 'flex flex-col justify-between items-start gap-2 w-[100%]'
      }>
      {label ? (
        <label
          className={classNames(
            'text-text-secondary',
            labelWithPadding && 'pl-[20px]',
            labelClassname
          )}>
          {label}
        </label>
      ) : null}
      <div
        className={classNames(
          'flex flex-row items-center w-full border-solid rounded-[20px] px-[20px] py-[12px] border',
          inputBorderColor ? inputBorderColor : 'border-input-border',
          containerClassName && containerClassName,
          disabled ? 'bg-input-disabled cursor-not-allowed' : 'bg-text-secondary'
        )}>
        {leftIcon}
        <input
          disabled={disabled}
          {...inputProps}
          className={classNames(
            'input w-full justify-between placeholder:text-[16px] relative',
            className
          )}
          type={htmlType}
          ref={ref}
          onChange={(event) => disabled || onChange?.(event)}
        />
        {rightIcon && (
          <div
            className={classNames(
              'pl-1 cursor-pointer transition-transform',
              arrowClassName ? arrowClassName : '',
              disabled && 'pointer-events-none'
            )}>
            {rightIcon}
          </div>
        )}
      </div>
    </div>
  );
});
