import { isProductionEnv } from 'src/api';

export const SOFTSWISS_FRAME_DOMAIN = isProductionEnv
  ? 'https://9.ssssdomain.org/'
  : 'https://dev.ssssdomain.org';

export const SOFTSWISS_SUPPORTED_LANGUAGES = {
  en: 'en',
  de: 'de',
  pl: 'pl'
};
