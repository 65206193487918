import { useEffect, useMemo, useState } from 'react';
import { StepOne } from './components/StepOne';
import { StepThree } from './components/StepThree';
import { StepTwo } from './components/StepTwo';
import reg from 'src/assets/images/reg.webp';
import gift from 'src/assets/images/gift.webp';
import { useTranslation } from 'react-i18next';
import { StepProps, StepsOption } from 'src/types';
import welcomeBonus from 'src/assets/images/welcomeBonus.webp';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useApiContext, useSignUpModal } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from 'src/components/ModalWrapper';
import { resetDepositModalShow } from 'src/hooks/useOpenDepositOnSessionStart';

export type SignUpModalProps = {
  initialStep: number | undefined;
  hideWhyNot?: boolean;
  onSignupSuccess?: () => void;
  isSuccessfullNavigateDisabled?: boolean;
};

export const SignUpModal = () => {
  const { t } = useTranslation();
  const { isLongModal } = useSignUpModal();
  const { closeModal, modalProps, openModal } = useModalContext<SignUpModalProps>();
  const { isAuthenticated } = useApiContext();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(modalProps?.initialStep || 0);
  const [availableSteps, setAvailableSteps] = useState<boolean[]>([
    false,
    modalProps?.initialStep === 1,
    false
  ]);
  const [completedSteps, setCompletedSteps] = useState<boolean[]>([false, false, false]);

  const steps: StepsOption[] = useMemo(
    () => [
      {
        title: 'First',
        children: ({ changeStep, completedSteps }: StepProps) => (
          <StepOne
            isCompleted={completedSteps[0]}
            onRegistrationSuccess={() => {
              changeStep(1);
            }}
            changeStep={changeStep}
            disableFields={completedSteps[0]}
          />
        )
      },
      {
        title: 'Second',
        children: ({ changeStep }: StepProps) => <StepTwo changeStep={changeStep} />
      },
      {
        title: 'Last',
        children: ({ completedSteps }: StepProps) => <StepThree disableFields={completedSteps[2]} />
      }
    ],
    []
  );

  const handleChangeStep = (newStep: number) => {
    setCurrentStep(newStep);

    const nextAvailableStepsState = [...availableSteps];
    const nextCompletedStepsState = [...completedSteps];

    // available
    nextAvailableStepsState[newStep] = true;

    // completed
    if (newStep !== 0) {
      nextCompletedStepsState[newStep - 1] = true;
    }

    setAvailableSteps(nextAvailableStepsState);
    setCompletedSteps(nextCompletedStepsState);
  };

  useEffect(() => {
    if (isAuthenticated && !completedSteps[0]) {
      const nextCompletedStepsState = [...completedSteps];
      nextCompletedStepsState[0] = true;
      setCompletedSteps(nextCompletedStepsState);
    }
  }, [completedSteps, isAuthenticated]);

  const StepComponent = steps[currentStep].children;

  return (
    <ModalWrapper
      onClose={() => {
        if (!isAuthenticated && !modalProps?.hideWhyNot) {
          openModal('BACK_TO_SIGNUP');
        }
        if (isAuthenticated && modalProps?.onSignupSuccess) {
          modalProps.onSignupSuccess();
        }
        if (isAuthenticated) {
          resetDepositModalShow();
        }
      }}>
      <div className="w-full flex flex-col h-full jsm:w-[300px] jxsm:w-full jlg:flex-row-reverse jxl:flex-row-reverse jxxl:flex-row-reverse jlg:w-[690px] jxl:w-[760px] jxxl:w-[760px] ">
        <div
          className="relative px-4 py-2 flex justify-end jlg:w-[50%] jxl:w-[50%] jxxl:w-[50%] "
          onClick={() => {
            closeModal();
            navigate('/bonuses');
          }}>
          <img
            src={reg}
            alt="registration"
            className="rounded-t-[20px] w-full absolute h-full top-0 left-0 jlg:hidden jxl:hidden jxxl:hidden"
          />
          <img
            src={welcomeBonus}
            alt="welcome bonus"
            className="w-full absolute hidden h-full top-0 left-0 object-cover object-left-bottom jlg:block jxl:block jxxl:block"
          />
          <div className="relative z-10 w-2/3 jlg:pt-[7%] jxl:pt-[7%] jxxl:pt-[7%] jlg:w-[80%] jxl:w-[80%] jxxl:w-[80%] jlg:text-center jxl:text-center jxxl:text-center jlg:mx-auto jxl:mx-auto jxxl:mx-auto">
            <div className="font-bold text-[12px] text-white jlg:text-[26px] jxl:text-[26px] jxxl:text-[26px] jlg:mb-[2%] jxl:mb-[2%] jxxl:mb-[2%]">
              {t('general.welcomePackBonus')}
            </div>
            <div className="font-bold text-base text-yellow jlg:text-[48px] jxl:text-[48px] jxxl:text-[48px] jlg:leading-[110%] jxl:leading-[110%] jxxl:leading-[110%] ">
              <p>{t('registration.banerText')}</p>
            </div>
          </div>
        </div>
        <div className="jlg:w-[50%] jxl:w-[50%] jxxl:w-[50%] overflow-y-auto modal-wrapper">
          <div className="my-1 pt-[5px] mb-[10px] md:my-3 md:pt-[10px] md:mb-[20px]">
            <p className="font-bold text-2xl text-center mb-1 md:mb-4">{t('general.signUp')}</p>
            {isLongModal && (
              <div className="flex justify-center items-center gap-3">
                {steps.map((item, index) => {
                  return (
                    <div
                      key={item.title}
                      className={classNames(
                        availableSteps[index]
                          ? 'cursor-pointer text-border-step'
                          : 'cursor-no-drop',
                        completedSteps[index] && 'bg-active-step text-border-step',
                        'flex text-xs font-bold text-center justify-center items-center min-h-[30px] h-full max-w-[30px] w-full rounded-full border-border-step border'
                      )}
                      onClick={() => availableSteps[index] && handleChangeStep(index)}>
                      {index + 1}
                    </div>
                  );
                })}
                <div className="flex justify-center items-center min-h-[30px] h-full max-w-[30px] w-full rounded-full border-border-step border">
                  <img src={gift} alt="gift" className="w-[15px]" />
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-center items-center px-[15px] pb-3">
            <StepComponent
              changeStep={handleChangeStep}
              availableSteps={availableSteps}
              completedSteps={completedSteps}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
