import { FC } from 'react';
import { AttentionModal } from 'src/components/Modal/AttentionBonus';
import { BackToSignup } from 'src/components/Modal/BackToSignup';
import { BonusModal } from 'src/components/Modal/BonusModal';
import { ChangeCurrencyModal } from 'src/components/Modal/ChangeCurrencyModal';
import { DepositCryptoModal } from 'src/components/Modal/DepositCryptoModal';
import { DepositModal } from 'src/components/Modal/DepositModal';
import { DepositPaymentInfo } from 'src/components/Modal/DepositPaymentInfo';
import { FormFieldsModal } from 'src/components/Modal/FormFieldsModal';
import { FullScreenImage } from 'src/components/Modal/FullScreenImage';
import { GameNotificationModal } from 'src/components/Modal/GameNotificationModal';
import GameTournament from 'src/components/Modal/GameTournament';
import GameTournamentMembers from 'src/components/Modal/GameTournamentMembers';
import { HellocleverResultModal } from 'src/components/Modal/HellocleverResult';
import { IosSetupModal } from 'src/components/Modal/IosSetupModal';
import { JackpotWinModal } from 'src/components/Modal/JackpotWinModal/JackpotWinModal';
import { LandingFirstPopUp } from 'src/components/Modal/LandingFirstPopUp';
import { LandingSecondPopUp } from 'src/components/Modal/LandingSecondPopUp';
import { LoginModal } from 'src/components/Modal/LoginModal';
import { NewLocalTournament } from 'src/components/Modal/NewLocalTournament';
import { NewTournament } from 'src/components/Modal/NewTournament';
import { PopUpFreeSpins } from 'src/components/Modal/PopUpFreeSpins';
import { ResetPasswordModal } from 'src/components/Modal/ResetPasswordModal';
import { SignUpModal } from 'src/components/Modal/Signup';
import { TournamentRequiredLevel } from 'src/components/Modal/TournamentRequiredLevel';
import { VipRulesModal } from 'src/components/Modal/VipRulesModal';
import { WinnerTournamentModal } from 'src/components/Modal/WinnerTournament';
import ShopModalEvent from 'src/components/ShopEvent/ShopModalEvent';

export type ModalMapKeys =
  | 'LOGIN'
  | 'SIGNUP'
  | 'BACK_TO_SIGNUP'
  | 'RESET_PASSWORD'
  | 'IOSSETUP'
  | 'POPUP_DEPOSIT'
  | 'POPUP_LANDING_FIRST'
  | 'POPUP_LANDING_SECOND'
  | 'FORM_FIELDS'
  | 'DEPOSIT_CRYPTO'
  | 'BALANCE_CHANGE_CURRENCY'
  | 'ATTENTION_BONUS'
  | 'BONUS'
  | 'VIP_RULES'
  | 'DEPOSIT_PAYMENT_INFO'
  | 'NEW_TOURNAMENT'
  | 'NEW_LOCAL_TOURNAMENT'
  | 'POPUP_FREESPINS'
  | 'GAME_NOTIFICATION'
  | 'DEPOSIT_HELLOCLEVER_RESULT'
  | 'TOURNAMENT_WIN'
  | 'JACKPOT_WIN'
  | 'GAME_TOURNAMENT'
  | 'TOURNAMENT_MEMBERS'
  | 'FULLSCREEN_IMAGE'
  | 'SHOP_MODAL_EVENT'
  | 'TOURNAMENT_LOW_LEVEL';

export type ModalMap = {
  [modalName in ModalMapKeys]: {
    component: FC;
    authOnly: boolean;
    priority: number;
  };
};

export const MODAL_MAP: ModalMap = {
  // Модалки, относящиеся к авторизации. Имеют самый высокий приоритет.
  LOGIN: {
    component: () => <LoginModal />,
    authOnly: false,
    priority: 1
  },
  SIGNUP: {
    component: () => <SignUpModal />,
    authOnly: false,
    priority: 1
  },
  RESET_PASSWORD: {
    component: () => <ResetPasswordModal />,
    authOnly: false,
    priority: 1
  },

  // Модалки, относящиеся к депозиту. Имеют самый высокий приоритет на равне с регистрацией.
  POPUP_DEPOSIT: {
    component: () => <DepositModal />,
    authOnly: false,
    priority: 1
  },
  DEPOSIT_CRYPTO: {
    component: () => <DepositCryptoModal />,
    authOnly: false,
    priority: 2
  },
  FORM_FIELDS: {
    component: () => <FormFieldsModal />,
    authOnly: false,
    priority: 2
  },
  DEPOSIT_HELLOCLEVER_RESULT: {
    component: () => <HellocleverResultModal />,
    authOnly: false,
    priority: 3
  },
  DEPOSIT_PAYMENT_INFO: {
    component: () => <DepositPaymentInfo />,
    authOnly: false,
    priority: 3
  },

  // Модалки, относящиеся к победе в джекпоте или турнире. Имеют один из самых высоких приоритетов.
  JACKPOT_WIN: {
    component: () => <JackpotWinModal />,
    authOnly: false,
    priority: 10
  },
  TOURNAMENT_WIN: {
    component: () => <WinnerTournamentModal />,
    authOnly: false,
    priority: 10
  },

  // Модалки, относящиеся к начислениям бонусов и фриспинов. Имеют достаточно высокий приоритет.
  BONUS: {
    component: () => <BonusModal />,
    authOnly: false,
    priority: 20
  },
  POPUP_FREESPINS: {
    component: () => <PopUpFreeSpins />,
    authOnly: false,
    priority: 20
  },

  // Модалки, относящиеся к изменению активной валюты пользователя. Имеют средний приоритет.
  BALANCE_CHANGE_CURRENCY: {
    component: () => <ChangeCurrencyModal />,
    authOnly: false,
    priority: 50
  },
  ATTENTION_BONUS: {
    component: () => <AttentionModal />,
    authOnly: false,
    priority: 50
  },

  // Все остальные модалки. Имеют чуть ниже среднего - низкий приоритет.
  NEW_TOURNAMENT: {
    component: () => <NewTournament />,
    authOnly: false,
    priority: 60
  },
  NEW_LOCAL_TOURNAMENT: {
    component: () => <NewLocalTournament />,
    authOnly: false,
    priority: 60
  },

  GAME_NOTIFICATION: {
    component: () => <GameNotificationModal />,
    authOnly: false,
    priority: 60
  },

  GAME_TOURNAMENT: {
    component: () => <GameTournament />,
    authOnly: false,
    priority: 60
  },
  TOURNAMENT_MEMBERS: {
    component: () => <GameTournamentMembers />,
    authOnly: false,
    priority: 60
  },

  FULLSCREEN_IMAGE: {
    component: () => <FullScreenImage />,
    authOnly: false,
    priority: 100
  },
  BACK_TO_SIGNUP: {
    component: () => <BackToSignup />,
    authOnly: false,
    priority: 100
  },
  VIP_RULES: {
    component: () => <VipRulesModal />,
    authOnly: false,
    priority: 100
  },
  IOSSETUP: {
    component: () => <IosSetupModal />,
    authOnly: false,
    priority: 100
  },
  POPUP_LANDING_FIRST: {
    component: () => <LandingFirstPopUp />,
    authOnly: false,
    priority: 100
  },
  POPUP_LANDING_SECOND: {
    component: () => <LandingSecondPopUp />,
    authOnly: false,
    priority: 100
  },
  SHOP_MODAL_EVENT: {
    component: () => <ShopModalEvent />,
    authOnly: true,
    priority: 100
  },
  TOURNAMENT_LOW_LEVEL: {
    component: () => <TournamentRequiredLevel />,
    authOnly: true,
    priority: 100
  }
};
