import { twMerge } from 'tailwind-merge';
import { BadgeWrapper } from '../BadgeWrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { totalJackpot } from 'src/components/DrawsBetweenJackpots/fakeSliderJackpots';

const compactFormatter = Intl.NumberFormat('en', { notation: 'compact' });

interface JackpotBadgeProps {
  jackpotBadge: {
    jackpotCode: string;
    currency: string;
    badgeColor: string;
  };
  shouldShowTitle: boolean;
  badgeGame: { id: number; title: string; style: string } | null;
}

export const JackpotBadge: FC<JackpotBadgeProps> = ({
  jackpotBadge,
  shouldShowTitle,
  badgeGame
}) => {
  const { t } = useTranslation();
  return (
    <BadgeWrapper>
      <div
        className={twMerge(
          'py-[5px] pr-[10%] pl-[5px] flex gap-1 rounded-tl-[11px] text-[10px] text-gg_badge_text_jackpot',
          jackpotBadge.badgeColor
        )}
        style={{ clipPath: 'polygon(0 0, 100% 0%, 90% 100%, 0% 100%)' }}>
        <span className="font-bold">
          {compactFormatter.format(
            totalJackpot[jackpotBadge.currency][jackpotBadge.jackpotCode].to
          )}
        </span>
        <span>{jackpotBadge.currency}</span>
      </div>
      {shouldShowTitle && badgeGame && (
        <div className={`px-1.5 py-1 mt-[4px] ml-[3px] font-bold text-xs w-fit ${badgeGame.style}`}>
          {t(badgeGame.title)}
        </div>
      )}
    </BadgeWrapper>
  );
};
