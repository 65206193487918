import {
  SlotsMobile,
  ProvidersMobile,
  FilledSlotsMobile,
  FilledProvidersMobile,
  FilledLiveCasinoTvMobile,
  LiveCasinoTvMobile,
  NavbarSearchIconActive,
  NavbarSearchIcon,
  NavbarDeposit,
  NavbarSignUp
} from '../svg';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '../../hooks/useDrawer';
import { useLocation, useNavigate } from 'react-router-dom';
import { memo, useCallback, useEffect, useState } from 'react';
import { useApiContext, useDeviceDetect } from 'react-easyrocket';
import { SingleNavbarItem } from './SingleNavbarItem';
import { GameCategoryName } from 'src/constants/gameCategories';
import { useModalContext } from 'src/hooks/useModalContext';
import classNames from 'classnames';

const _Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isIos, isMobile } = useDeviceDetect();
  const { isAuthenticated } = useApiContext();
  const { openModal } = useModalContext();
  const {
    toggleIsOpenDrawer,
    isTopSearchDrawerOpen,
    toggleBottomDrawer,
    isBottomDrawerOpen,
    closeBottomDrawer,
    isTopDrawerOpen,
    closeTopDrawer
  } = useDrawer();

  const [activeCategory, setActiveCategory] = useState({
    GAME: false,
    LIVE: false,
    VIP: false,
    PROVIDERS: false
  });

  useEffect(() => {
    const liveGamesCategories: GameCategoryName[] = [
      'live',
      'blackjack',
      'baccarat',
      'poker',
      'roulette'
    ];

    setActiveCategory({
      PROVIDERS: isBottomDrawerOpen ? true : false,
      GAME: location.pathname.includes('slots'),
      VIP: location.pathname.includes('vip'),
      LIVE: liveGamesCategories.some((word) => location.pathname.includes(word))
    });
  }, [isBottomDrawerOpen, location]);

  const handleSlotsClick = useCallback(() => {
    navigate('/games/slots');
    closeBottomDrawer?.();
  }, [closeBottomDrawer, navigate]);

  const handleLiveClick = useCallback(() => {
    navigate('/games/live');
    closeBottomDrawer?.();
  }, [closeBottomDrawer, navigate]);

  const handleProvidersClick = useCallback(() => {
    toggleBottomDrawer?.();
    isTopDrawerOpen && closeTopDrawer?.();
  }, [closeTopDrawer, isTopDrawerOpen, toggleBottomDrawer]);

  const handleCentalButtonClick = useCallback(() => {
    openModal(isAuthenticated ? 'POPUP_DEPOSIT' : 'SIGNUP');
  }, [isAuthenticated, openModal]);

  return (
    <div className="z-20">
      <div
        className={classNames(
          'flex items-center justify-around w-full px-[5px] bg-navbar_bg',
          isMobile && isIos && 'pb-[10px]'
        )}>
        <SingleNavbarItem
          onClick={handleSlotsClick}
          textHighlightCondition={activeCategory.GAME}
          title={t('categoriesItem.slots')}>
          {activeCategory.GAME ? <FilledSlotsMobile /> : <SlotsMobile />}
        </SingleNavbarItem>
        <SingleNavbarItem
          onClick={handleLiveClick}
          textHighlightCondition={activeCategory.LIVE}
          title={t('gameCategories.live')}>
          {activeCategory.LIVE ? <FilledLiveCasinoTvMobile /> : <LiveCasinoTvMobile />}
        </SingleNavbarItem>

        <SingleNavbarItem
          onClick={handleCentalButtonClick}
          textHighlightCondition={false}
          title={isAuthenticated ? t('general.deposit') : t('general.signUp')}>
          {isAuthenticated ? <NavbarDeposit /> : <NavbarSignUp />}
        </SingleNavbarItem>

        <SingleNavbarItem
          onClick={handleProvidersClick}
          textHighlightCondition={activeCategory.PROVIDERS}
          title={t('mobileMenuItem.providers')}>
          <div className="flex w-[26px] h-[26px] justify-center items-center">
            {activeCategory.PROVIDERS ? <FilledProvidersMobile /> : <ProvidersMobile />}
          </div>
        </SingleNavbarItem>

        <SingleNavbarItem
          onClick={toggleIsOpenDrawer}
          textHighlightCondition={false}
          title={t('general.search')}>
          {isTopSearchDrawerOpen ? <NavbarSearchIconActive /> : <NavbarSearchIcon />}
        </SingleNavbarItem>
      </div>
    </div>
  );
};

export const Navbar = memo(_Navbar);
