import { FC } from 'react';
import { Provider } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { ProviderButton } from 'src/components/ProviderButton/ProviderButton';
import { Spin } from 'src/components/svg';
import { twJoin } from 'tailwind-merge';
import moonDog from 'src/assets/images/moonDog.webp';

interface ProvidersSearchResults {
  isFetching: boolean;
  isFetched: boolean;
  providersToRender: Provider[];
}

export const ProvidersSearchResults: FC<ProvidersSearchResults> = ({
  isFetched,
  isFetching,
  providersToRender
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={twJoin(
        isFetching && !isFetched
          ? 'flex items-center justify-center w-full h-full'
          : ' w-[90%] grid grid-cols-1 568:grid-cols-2 gap-2 568:gap-5 overflow-y-scroll items-stretch'
      )}>
      {providersToRender?.map((provider) => (
        <ProviderButton provider={provider} key={provider.id} />
      ))}
      {isFetching && (
        <div className="h-full w-full flex justify-center items-center self-center text-center">
          <Spin classNameSvg="w-[30px] h-[30px]" />
        </div>
      )}
      {!isFetching && providersToRender?.length === 0 && (
        <>
          <p className="text-gcp_no_providers_searching_text m-auto text-center">
            {t('general.noProvidersFound')}
          </p>
          <img src={moonDog} className="w-[180px] m-auto" alt="providers moon dog" />
        </>
      )}
      <div className="h-5 bg-gcp_no_providers_searching_bg"></div>
    </div>
  );
};
