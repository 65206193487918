export const NavbarSignUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="4 0 50 50" fill="none">
    <g filter="url(#filter0_d_16746_256895)">
      <circle cx="29" cy="25" r="25" fill="#FFF000" />
    </g>
    <path
      d="M36.7373 25.1812C36.6445 25.0863 36.5337 25.0109 36.4114 24.9593C36.2892 24.9076 36.1579 24.8809 36.0252 24.8805C35.8925 24.8802 35.761 24.9062 35.6385 24.9572C35.5159 25.0081 35.4048 25.083 35.3115 25.1773C35.2181 25.2717 35.1445 25.3837 35.0949 25.5067C35.0453 25.6298 35.0207 25.7616 35.0225 25.8943C35.0244 26.027 35.0526 26.158 35.1056 26.2796C35.1585 26.4013 35.2352 26.5112 35.3311 26.603C36.1795 27.4378 36.8526 28.4337 37.3109 29.5322C37.7692 30.6307 38.0035 31.8097 38 33C38 34.2231 34.4941 36 29 36C23.5059 36 20 34.2222 20 32.998C19.9967 31.8159 20.2278 30.6449 20.6801 29.5527C21.1323 28.4606 21.7967 27.4689 22.6348 26.6353C22.7296 26.5429 22.8051 26.4326 22.8569 26.3107C22.9088 26.1889 22.9359 26.058 22.9367 25.9256C22.9376 25.7932 22.9121 25.662 22.8618 25.5395C22.8115 25.4171 22.7375 25.3058 22.6438 25.2122C22.5502 25.1186 22.439 25.0445 22.3165 24.9942C22.194 24.9439 22.0628 24.9185 21.9304 24.9193C21.798 24.9201 21.6671 24.9473 21.5453 24.9991C21.4235 25.0509 21.3132 25.1264 21.2207 25.2212C20.1962 26.2404 19.3839 27.4527 18.8311 28.7879C18.2783 30.1232 17.9958 31.5548 18 33C18 36.2476 23.667 38 29 38C34.333 38 39.9999 36.2476 39.9999 33C40.0044 31.5451 39.7181 30.104 39.1578 28.7613C38.5975 27.4186 37.7745 26.2014 36.7373 25.1812Z"
      fill="black"
    />
    <path
      d="M29 26C30.3845 26 31.7378 25.5895 32.889 24.8203C34.0401 24.0511 34.9373 22.9579 35.4671 21.6788C35.9969 20.3997 36.1356 18.9922 35.8655 17.6344C35.5954 16.2765 34.9287 15.0292 33.9497 14.0503C32.9708 13.0713 31.7235 12.4046 30.3656 12.1345C29.0078 11.8644 27.6003 12.003 26.3212 12.5328C25.0421 13.0627 23.9489 13.9599 23.1797 15.111C22.4106 16.2622 22 17.6155 22 19C22.0022 20.8559 22.7404 22.6351 24.0526 23.9474C25.3649 25.2597 27.1441 25.9979 29 26ZM29 14C29.9889 14 30.9556 14.2932 31.7778 14.8427C32.6001 15.3921 33.2409 16.173 33.6194 17.0866C33.9978 18.0002 34.0968 19.0055 33.9039 19.9755C33.711 20.9454 33.2348 21.8363 32.5355 22.5355C31.8363 23.2348 30.9453 23.711 29.9754 23.9039C29.0055 24.0969 28.0002 23.9978 27.0866 23.6194C26.173 23.241 25.3921 22.6001 24.8427 21.7779C24.2933 20.9556 24 19.9889 24 19C24.0016 17.6744 24.5289 16.4035 25.4662 15.4662C26.4035 14.5289 27.6744 14.0016 29 14Z"
      fill="black"
    />
    <defs>
      <filter
        id="filter0_d_16746_256895"
        x="0"
        y="0"
        width="58"
        height="58"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16746_256895" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_16746_256895"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const NavbarDeposit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="4 0 50 50" fill="none">
    <g filter="url(#filter0_d_16746_257633)">
      <circle cx="29" cy="25" r="25" fill="#FFF000" />
    </g>
    <path
      d="M40 18H20C19.7348 18 19.4804 17.8946 19.2929 17.7071C19.1054 17.5196 19 17.2652 19 17C19 16.7348 19.1054 16.4804 19.2929 16.2929C19.4804 16.1054 19.7348 16 20 16H37C37.2652 16 37.5196 15.8946 37.7071 15.7071C37.8946 15.5196 38 15.2652 38 15C38 14.7348 37.8946 14.4804 37.7071 14.2929C37.5196 14.1054 37.2652 14 37 14H20C19.2044 14 18.4413 14.3161 17.8787 14.8787C17.3161 15.4413 17 16.2044 17 17V33C17 33.7956 17.3161 34.5587 17.8787 35.1213C18.4413 35.6839 19.2044 36 20 36H40C40.5304 36 41.0391 35.7893 41.4142 35.4142C41.7893 35.0391 42 34.5304 42 34V20C42 19.4696 41.7893 18.9609 41.4142 18.5858C41.0391 18.2107 40.5304 18 40 18ZM40 34H20C19.7348 34 19.4804 33.8946 19.2929 33.7071C19.1054 33.5196 19 33.2652 19 33V19.8287C19.3211 19.9426 19.6593 20.0005 20 20H40V34ZM34 26.5C34 26.2033 34.088 25.9133 34.2528 25.6666C34.4176 25.42 34.6519 25.2277 34.926 25.1142C35.2001 25.0007 35.5017 24.9709 35.7926 25.0288C36.0836 25.0867 36.3509 25.2296 36.5607 25.4393C36.7704 25.6491 36.9133 25.9164 36.9712 26.2074C37.0291 26.4983 36.9993 26.7999 36.8858 27.074C36.7723 27.3481 36.58 27.5824 36.3334 27.7472C36.0867 27.912 35.7967 28 35.5 28C35.1022 28 34.7206 27.842 34.4393 27.5607C34.158 27.2794 34 26.8978 34 26.5Z"
      fill="#1F1F1F"
    />
    <defs>
      <filter
        id="filter0_d_16746_257633"
        x="0"
        y="0"
        width="58"
        height="58"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16746_257633" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_16746_257633"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
