import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useApiContext, useGetBonusesTransactions } from 'react-easyrocket';
import { useModalContext } from './useModalContext';

export function useTrackIncomingBonusTransaction() {
  const { openModal, openedModalName } = useModalContext();
  const { isAuthenticated } = useApiContext();
  const { pathname } = useLocation();

  const { arrayBonusesTansactions, refetch, isFetching } = useGetBonusesTransactions(
    {
      bonus_type: 'All',
      status: ['Pending']
    },
    { isNeedPages: false }
  );

  useEffect(() => {
    if (!isAuthenticated) return;
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isAuthenticated]);

  useEffect(() => {
    if (arrayBonusesTansactions.length > 0 && !isFetching && openedModalName !== 'SIGNUP') {
      if (arrayBonusesTansactions[0].type !== 16 && arrayBonusesTansactions[0].type !== 1) {
        openModal('BONUS', {
          props: { bonusId: arrayBonusesTansactions[0].id },
          replace: true
        });
      }
    }
  }, [arrayBonusesTansactions, isFetching, openModal, openedModalName]);
}
