import { FC } from 'react';
import { useDrawer } from '../../hooks/useDrawer';

type BurgerProps = {
  customOnClick?: () => void;
};

export const Burger: FC<BurgerProps> = ({ customOnClick }) => {
  const { openLeft, closeBottomDrawer } = useDrawer();

  const handleClick = () => {
    openLeft?.();
    closeBottomDrawer?.();
  };

  const clickHandler = customOnClick ? customOnClick : handleClick;

  return (
    <button className="flex flex-row flex-wrap items-center cursor-pointer" onClick={clickHandler}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.25 10.1562C7.25 9.62132 7.74579 9.1875 8.35714 9.1875H21.6429C22.2552 9.1875 22.75 9.62132 22.75 10.1562C22.75 10.6921 22.2552 11.125 21.6429 11.125H8.35714C7.74579 11.125 7.25 10.6921 7.25 10.1562ZM7.25 15C7.25 14.4642 7.74579 14.0312 8.35714 14.0312H21.6429C22.2552 14.0312 22.75 14.4642 22.75 15C22.75 15.5358 22.2552 15.9688 21.6429 15.9688H8.35714C7.74579 15.9688 7.25 15.5358 7.25 15ZM21.6429 20.8125H8.35714C7.74579 20.8125 7.25 20.3796 7.25 19.8438C7.25 19.3079 7.74579 18.875 8.35714 18.875H21.6429C22.2552 18.875 22.75 19.3079 22.75 19.8438C22.75 20.3796 22.2552 20.8125 21.6429 20.8125Z"
          fill="white"
        />
        <rect
          x="0.645833"
          y="0.645833"
          width="28.7083"
          height="28.7083"
          rx="14.3542"
          stroke="white"
          strokeWidth="1.29167"
        />
      </svg>
    </button>
  );
};
