import { useTranslation } from 'react-i18next';

export const LifeBadge = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center absolute bottom-[60px] rounded-md left-2 bg-gg_badge_live_bg uppercase p-1 pl-3 text-gg_badge_text_live font-bold text-xs after:content-[''] after:rounded-full after:bg-gg_badge_point_bg after:w-1.5 after:h-1.5 after:absolute after:left-1 after:animate-blink z-20">
      {t('categoryBadgeGame.live')}
    </div>
  );
};
