export function smarticoInit() {
  (function (d, r, b, h, s) {
    // @ts-ignore
    h = d.getElementsByTagName('head')[0];
    // @ts-ignore
    s = d.createElement('script');
    // @ts-ignore
    s.onload = b;
    // @ts-ignore
    s.src = r;
    // @ts-ignore
    h.appendChild(s);
  })(document, 'https://libs.smartico.ai/smartico.js', function () {
    window._smartico.init('173b7cde-dde0-4b57-8b36-d79381ea8749-4', { brand_key: '72478d7e' });
  });
}

/** Выполнять на каждой странице */
export function smarticoSetUserData(
  userId: number | string | undefined | null,
  lang: string | null
) {
  if (userId) window._smartico_user_id = userId;
  if (lang) window._smartico_language = lang;
}

/** Выполнять на логаут */
export function smarticoLogout() {
  window._smartico_user_id = null;
  window._smartico_language = null;
}

export function smarticoGo() {
  if (!window._smartico) return;
  window._smartico.dp('dp:gf');
}
