import { useCallback } from 'react';
import { Input } from 'src/components/Inputs/Input';
import { Button } from 'src/components/Buttons/Button';
import { RadioGroup } from 'src/components/RadioGroup';
import { Dropdown } from 'src/components/Dropdowns/Dropdown';
import { days, months, years } from 'src/constants';
import dayjs from 'dayjs';
import {
  useForm,
  FieldType,
  validateMaxLength,
  validateDateOfBirth
} from 'src/hooks/useForm/index';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useApiContext, useGetFullInfo, useUpdateInfo } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';

export type Values = {
  name: string;
  surname: string;
  gender: string;
  day: string | undefined;
  month: string | undefined;
  year: string | undefined;
};

export const StepTwo = ({
  changeStep,
  disableFields = false
}: {
  changeStep: (value: number) => void;
  disableFields?: boolean;
}) => {
  const { isAuthenticated } = useApiContext();
  const { t } = useTranslation();
  const { closeModal } = useModalContext();

  const handleChangeStep = useCallback(() => {
    changeStep(2);
  }, [changeStep]);
  const { mutateAsync, isLoading } = useUpdateInfo({ onSuccess: handleChangeStep });
  const {
    values,
    errors,
    isFormValid,
    setValues,
    fieldsProps: { nameProps, surnameProps, dayProps, monthProps, yearProps, genderProps },
    touched
  } = useForm<{
    name: FieldType.Text;
    surname: FieldType.Text;
    day: FieldType.Dropdown;
    month: FieldType.Dropdown;
    year: FieldType.Dropdown;
    gender: FieldType.Radio;
  }>({
    name: {
      placeholder: `${t('general.firstName')}`,
      fieldType: FieldType.Text,
      validation: [validateMaxLength(256)]
    },
    surname: {
      placeholder: `${t('general.lastName')}`,
      fieldType: FieldType.Text,
      validation: [validateMaxLength(256)]
    },
    day: {
      placeholder: `${t('general.day')}`,
      fieldType: FieldType.Dropdown,
      options: days,
      validation: [validateDateOfBirth]
    },
    month: {
      placeholder: `${t('general.month')}`,
      options: months,
      fieldType: FieldType.Dropdown,
      validation: []
    },
    year: {
      placeholder: `${t('general.year')}`,
      options: years,
      fieldType: FieldType.Dropdown,
      validation: []
    },
    gender: {
      fieldType: FieldType.Radio,
      options: [
        { id: 'm', name: `${t('accountProfileMain.male')}` },
        { id: 'f', name: `${t('accountProfileMain.female')}` }
      ]
    }
  });

  const prepareDate = useCallback(() => {
    return values.day && values.month && values.year
      ? dayjs()
          .date(parseInt(values.day))
          .month(parseInt(values.month) - 1)
          .year(parseInt(values.year))
          .format('YYYY-MM-DD')
      : undefined;
  }, [values.day, values.month, values.year]);

  const handleClick = () => {
    if (!isFormValid) return;
    mutateAsync({
      name: values.name ? values.name.replaceAll(' ', '') : undefined,
      surname: values.surname ? values.surname.replaceAll(' ', '') : undefined,
      sex: values.gender ? values.gender : undefined,
      birthday: prepareDate()
    });
  };

  useGetFullInfo({
    onSuccess: (response) => {
      const birthDate = dayjs(response?.data?.birthday);

      setValues({
        name: response.data.name,
        surname: response.data.surname,
        gender: response.data.sex,
        day: response?.data?.birthday === '' ? undefined : `${birthDate.date()}`,
        month: response?.data?.birthday === '' ? undefined : `${birthDate.month() + 1}`,
        year: response?.data?.birthday === '' ? undefined : `${birthDate.year()}`
      });
    }
  });

  return (
    <form
      className="flex flex-col gap-5 w-full items-center"
      onSubmit={(e) => e.preventDefault()}
      autoComplete="off">
      <Input
        {...nameProps}
        autoComplete="off"
        disabled={disableFields}
        className="placeholder:text-[14px]"
      />
      {touched.name && errors.name && <span className="text-[red] text-xs">{errors.name}</span>}
      <Input
        {...surnameProps}
        autoComplete="off"
        disabled={disableFields}
        className="placeholder:text-[14px]"
      />
      {touched.surname && errors.surname && (
        <span className="text-[red] text-xs">{errors.surname}</span>
      )}
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-around gap-2 self-center w-full">
          <Dropdown
            {...dayProps}
            disabled={disableFields}
            classNameArrow="right-2.5 jsm:right-[5px]"
          />
          <Dropdown
            {...monthProps}
            disabled={disableFields}
            classNameArrow="right-2.5 jsm:right-[5px]"
          />
          <Dropdown
            {...yearProps}
            disabled={disableFields}
            classNameArrow="right-2.5 jsm:right-[5px]"
          />
        </div>
        {errors.day && <span className="text-xs text-[red] mt-1">{t(errors.day)}</span>}
      </div>
      <div className="z-0 w-full ml-[15px]">
        <RadioGroup
          {...genderProps}
          checkValue={values?.gender}
          labelsClassName="!text-[black] before:!bg-[black]"
        />
      </div>
      <Button
        title={`${t('registration.nextStep')}`}
        loading={isLoading}
        mode="button-secondary"
        className="normal-case"
        onClick={() => (isAuthenticated ? handleClick() : changeStep(2))}>
        {t('registration.nextStep')}
      </Button>
      <p className="w-full font-medium text-[12px] m-auto justify-center text-center my-2.5">
        <Trans
          i18nKey="registration.youCanAlsoFillInfo"
          components={{
            2: (
              <Link
                to="account/profile/main"
                onClick={closeModal}
                className="cursor-pointer text-color-link underline"
                aria-label="profile"
              />
            )
          }}
        />
      </p>
    </form>
  );
};
