import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldType, useForm, validateMaxLength, validateRequired } from '../../hooks/useForm';
import { Button } from '../Buttons/Button';
import { Input } from '../Inputs/Input';
import { useResetPassword } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';
import { openChat } from '../LiveChatWidget/utils';

export const ResetPasswordModal = () => {
  const [showSuccessInfo, setShowSuccessInfo] = useState(false);
  const { mutateAsync: resetPassword } = useResetPassword();
  const { openModal, closeModal } = useModalContext();
  const { t } = useTranslation();
  const {
    values,
    errors,
    fieldsProps: { emailProps },
    touched,
    isFormValid
  } = useForm<{ email: FieldType.Text }>({
    email: {
      placeholder: `${t('general.email')}`,
      fieldType: FieldType.Text,
      validation: [validateRequired, validateMaxLength(256)]
    }
  });

  const handleCreateAccountClick = () => {
    closeModal();
    openModal('SIGNUP');
  };

  const onResetPassword = async () => {
    const response = await resetPassword({ email: values.email });
    setShowSuccessInfo(response.data.success);
  };

  return (
    <ModalWrapper>
      <div className="flex flex-col items-center gap-[15px] p-[15px] py-[20px] w-[300px] jxsm:w-full">
        <p className="text-[24px] text-background-fivefold font-bold text-center tracking-[0.03em]">
          {t('modalResetPassword.title')}
        </p>
        {!showSuccessInfo ? (
          <>
            <p className="font-medium text-[12px] text-background-fivefold text-center w-[80%]">
              {t('modalResetPassword.subtitle')}
            </p>
            <Input {...emailProps} />
            {touched.email && errors.email && (
              <span className="text-[red] text-xs">{errors.email}</span>
            )}
            <Button
              disabled={!isFormValid}
              mode="button-primary"
              onClick={onResetPassword}
              title={`${t('modalResetPassword.title')}`}>
              {t('modalResetPassword.title')}
            </Button>
            <div className="flex flex-row">
              <p className="text-center font-medium text-[14px] text-background-fivefold">
                {t('modalResetPassword.notRegist')}
                <span
                  className="pl-[3px] font-thin text-color-link text-[14px] underline cursor-pointer"
                  onClick={handleCreateAccountClick}>
                  {t('modalResetPassword.createNewAcc')}
                </span>
              </p>
            </div>
          </>
        ) : (
          <>
            <p className="font-medium text-[12px] text-background-fivefold text-center w-[80%]">
              {t('modalResetPassword.thankYouText')}
            </p>
            <p className="font-medium text-[12px] text-background-fivefold text-center w-[80%]">
              {t('modalResetPassword.spamText')}
            </p>
            <p className="font-medium text-[12px] text-background-fivefold text-center w-[80%]">
              {t('modalResetPassword.notReceivedLetter')}{' '}
              <span
                className="text-game-badge-new cursor-pointer underline"
                onClick={() => {
                  closeModal();
                  openChat();
                }}>
                {t('modalResetPassword.support')}
              </span>
            </p>
            <Button mode="button-primary" onClick={closeModal} title="okay">
              {t('modalResetPassword.okay')}
            </Button>
          </>
        )}
      </div>
    </ModalWrapper>
  );
};
