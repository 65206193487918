import classNames from 'classnames';
import { FC, PropsWithChildren, memo } from 'react';

type SingleNavbarItem = {
  onClick?: () => void;
  textHighlightCondition: boolean;
  title: string;
};

const _SingleNavbarItem: FC<PropsWithChildren<SingleNavbarItem>> = ({
  onClick,
  children,
  textHighlightCondition,
  title
}) => {
  return (
    <div
      onClick={() => {
        onClick?.();
      }}
      className={classNames(
        'flex flex-col py-2 justify-between items-center h-full gap-2 cursor-pointer w-1/5 rounded',
        textHighlightCondition ? 'bg-bg-active-navbar-item' : ''
      )}>
      {children}
      <p
        className={classNames(
          'font-medium text-[9px] uppercase text-center leading-normal',
          textHighlightCondition ? 'text-yellow' : 'text-white'
        )}>
        {title}
      </p>
    </div>
  );
};

export const SingleNavbarItem = memo(_SingleNavbarItem);
