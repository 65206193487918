import { SOFTSWISS_FRAME_DOMAIN, SOFTSWISS_SUPPORTED_LANGUAGES } from '../config';

export const getSportUrl = (token: string | undefined, appLanguage: string) => {
  const languageToUse = appLanguage in SOFTSWISS_SUPPORTED_LANGUAGES ? appLanguage : 'en';

  if (!token) return `${SOFTSWISS_FRAME_DOMAIN}/${languageToUse}`;

  const searchParams = new URLSearchParams({
    session_request_id: token || ''
  });
  return `${SOFTSWISS_FRAME_DOMAIN}/${languageToUse}?${searchParams.toString()}`;
};
