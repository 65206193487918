import { GameData } from 'react-easyrocket';
import { DropsAndWinsBadge } from '../DropsAndWinsBadge';
import { DropsWinsBadge } from '../DropsWinsBadge';
import { FC } from 'react';
import { BLACKJACK_LEAGUE, DROPS_AND_WINS_CATEGORY } from '../../constants';
import { isBjLeagueVisible } from 'src/utils/bjLeague';

interface LightGameBadgesProps {
  game: GameData;
}

export const LightGameBadges: FC<LightGameBadgesProps> = ({ game }) => {
  const isDropsAndWins = game.categories?.some((category) => {
    return category.id === DROPS_AND_WINS_CATEGORY;
  });

  const isBjLeague =
    isBjLeagueVisible() &&
    game.categories?.some((category) => {
      return category.id === BLACKJACK_LEAGUE;
    });

  return <>{isDropsAndWins ? <DropsWinsBadge /> : isBjLeague && <DropsAndWinsBadge />}</>;
};
