export const FilledSlotsMobile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        d="M22.4141 3H19.2761C18.9525 3 18.6901 3.26234 18.6901 3.58594C18.6901 3.95496 18.3898 4.25523 18.0208 4.25523C15.6412 4.25523 14.3739 3 11.7448 3C10.0897 3 8.53398 3.44441 7.30988 4.24531V3.58594C7.30988 3.26234 7.04754 3 6.72395 3H3.58594C3.26234 3 3 3.26234 3 3.58594V11.1172C3 11.4408 3.26234 11.7031 3.58594 11.7031H6.72395C7.04754 11.7031 7.30988 11.4408 7.30988 11.1172C7.30988 9.71 8.45477 8.56512 9.86199 8.56512C11.1829 8.56512 12.6064 9.1927 14.2552 9.1927C14.6662 9.1927 15.0558 9.16844 15.4237 9.12008L13.8409 10.7029C10.6364 13.9073 7.39324 16.5263 7.39324 22.4141C7.39324 22.7377 7.65559 23 7.97918 23H15.5104C15.834 23 16.0964 22.7377 16.0964 22.4141C16.0964 16.5575 18.2363 13.4373 20.1243 10.6845C21.6032 8.52832 23 6.49176 23 3.58594C23 3.26234 22.7377 3 22.4141 3ZM19.158 10.0217C17.0291 13.1256 15.0658 16.2327 14.9314 21.8281H8.57711C8.78395 16.8578 11.6252 14.5762 14.6695 11.5315L18.4321 7.76887C18.4332 7.76781 18.4343 7.76672 18.4354 7.76562C18.664 7.53676 18.6639 7.16586 18.435 6.93711C18.2062 6.70844 17.8352 6.70848 17.6065 6.93727L17.6039 6.9398C16.8859 7.65707 15.7592 8.02082 14.2552 8.02082C12.7729 8.02082 11.3512 7.39324 9.86195 7.39324C8.00781 7.39324 6.46586 8.75516 6.18402 10.5312H4.17188V4.17188H6.13801V5.46875C6.13801 5.70574 6.28074 5.91937 6.49973 6.01008C6.71875 6.10074 6.97074 6.0507 7.13828 5.88305C8.22574 4.79559 9.90477 4.17188 11.7448 4.17188C14.1261 4.17188 15.3903 5.42711 18.0208 5.42711C18.8313 5.42711 19.5211 4.90074 19.7664 4.17188H21.8057C21.6325 6.41363 20.4782 8.09676 19.158 10.0217Z"
        fill="#FFF000"
      />
    </svg>
  );
};

export const FilledProvidersMobile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.2223 0H2.77771C1.24435 0.00183105 0.00183105 1.24435 0 2.77771V17.2223C0.00183105 18.7556 1.24435 19.9982 2.77771 20H17.2223C18.7556 19.9982 19.9982 18.7556 20 17.2223V2.77771C19.9982 1.24435 18.7556 0.00183105 17.2223 0ZM18.8889 17.2223C18.8879 18.1422 18.1422 18.8879 17.2223 18.8889H2.77771C1.85776 18.8879 1.11206 18.1422 1.11115 17.2223V2.77771C1.11206 1.85776 1.85776 1.11206 2.77771 1.11115H17.2223C18.1422 1.11206 18.8879 1.85776 18.8889 2.77771V17.2223Z"
        fill="#FFF000"
      />
      <path
        d="M11.6667 10C11.6667 10.9204 10.9204 11.6667 10 11.6667C9.07959 11.6667 8.33328 10.9204 8.33328 10C8.33328 9.07959 9.07959 8.33328 10 8.33328C10.9204 8.33328 11.6667 9.07959 11.6667 10Z"
        fill="#FFF000"
      />
      <path
        d="M16.6667 15C16.6667 15.9204 15.9204 16.6667 15 16.6667C14.0796 16.6667 13.3333 15.9204 13.3333 15C13.3333 14.0796 14.0796 13.3333 15 13.3333C15.9204 13.3333 16.6667 14.0796 16.6667 15Z"
        fill="#FFF000"
      />
      <path
        d="M6.66672 15C6.66672 15.9204 5.92041 16.6667 5 16.6667C4.07959 16.6667 3.33328 15.9204 3.33328 15C3.33328 14.0796 4.07959 13.3333 5 13.3333C5.92041 13.3333 6.66672 14.0796 6.66672 15Z"
        fill="#FFF000"
      />
      <path
        d="M16.6667 5C16.6667 5.92041 15.9204 6.66672 15 6.66672C14.0796 6.66672 13.3333 5.92041 13.3333 5C13.3333 4.07959 14.0796 3.33328 15 3.33328C15.9204 3.33328 16.6667 4.07959 16.6667 5Z"
        fill="#FFF000"
      />
      <path
        d="M6.66672 5C6.66672 5.92041 5.92041 6.66672 5 6.66672C4.07959 6.66672 3.33328 5.92041 3.33328 5C3.33328 4.07959 4.07959 3.33328 5 3.33328C5.92041 3.33328 6.66672 4.07959 6.66672 5Z"
        fill="#FFF000"
      />
    </svg>
  );
};

export const LiveCasinoTvMobile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        d="M21 6.39668H13.9711L17.8607 4.05605C18.0906 3.81454 18.0906 3.42283 17.8607 3.18132C17.6311 2.93956 17.2584 2.93956 17.0289 3.18132L13 5.5L8.97106 3.18132C8.74141 2.93956 8.36871 2.93956 8.13929 3.18132C7.90941 3.42283 7.90941 3.81454 8.13929 4.05605L12.0289 6.39668H5C3.89718 6.39668 3 7.34021 3 8.5V20.8967C3 22.0565 3.89718 23 5 23H21C22.1028 23 23 22.0565 23 20.8967V8.5C23 7.34021 22.1028 6.39668 21 6.39668ZM21.8235 20.8969C21.8235 21.3745 21.4541 21.763 21 21.763H5C4.54588 21.763 4.17647 21.3745 4.17647 20.8969V8.5C4.17647 8.02242 4.54588 7.63393 5 7.63393H21C21.4541 7.63393 21.8235 8.02242 21.8235 8.5V20.8969Z"
        fill="white"
      />
      <path
        d="M17.2059 14.2737L10.8136 11C10.6318 10.8896 10.4073 10.8896 10.2254 11C10.0435 11.1106 9.93129 11.3148 9.93129 11.5357V18.0837C9.93129 18.3047 10.0435 18.5088 10.2254 18.6194C10.3165 18.6746 10.4179 18.7023 10.5195 18.7023C10.6212 18.7023 10.7226 18.6746 10.8136 18.6194L17.2059 15.3455C17.3878 15.2348 17.5 15.0307 17.5 14.8097C17.5 14.5888 17.3878 14.3844 17.2059 14.2737ZM11.1078 17.0123V12.6072L15.7353 14.8095L11.1078 17.0123Z"
        fill="white"
      />
    </svg>
  );
};

export const FilledLiveCasinoTvMobile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        d="M21 6.39668H13.9711L17.8607 4.05605C18.0906 3.81454 18.0906 3.42283 17.8607 3.18132C17.6311 2.93956 17.2584 2.93956 17.0289 3.18132L13 5.5L8.97106 3.18132C8.74141 2.93956 8.36871 2.93956 8.13929 3.18132C7.90941 3.42283 7.90941 3.81454 8.13929 4.05605L12.0289 6.39668H5C3.89718 6.39668 3 7.34021 3 8.5V20.8967C3 22.0565 3.89718 23 5 23H21C22.1028 23 23 22.0565 23 20.8967V8.5C23 7.34021 22.1028 6.39668 21 6.39668ZM21.8235 20.8969C21.8235 21.3745 21.4541 21.763 21 21.763H5C4.54588 21.763 4.17647 21.3745 4.17647 20.8969V8.5C4.17647 8.02242 4.54588 7.63393 5 7.63393H21C21.4541 7.63393 21.8235 8.02242 21.8235 8.5V20.8969Z"
        fill="#FFF000"
      />
      <path
        d="M17.2059 14.2737L10.8136 11C10.6318 10.8896 10.4073 10.8896 10.2254 11C10.0435 11.1106 9.93129 11.3148 9.93129 11.5357V18.0837C9.93129 18.3047 10.0435 18.5088 10.2254 18.6194C10.3165 18.6746 10.4179 18.7023 10.5195 18.7023C10.6212 18.7023 10.7226 18.6746 10.8136 18.6194L17.2059 15.3455C17.3878 15.2348 17.5 15.0307 17.5 14.8097C17.5 14.5888 17.3878 14.3844 17.2059 14.2737ZM11.1078 17.0123V12.6072L15.7353 14.8095L11.1078 17.0123Z"
        fill="#FFF000"
      />
    </svg>
  );
};
