import { useSpring, animated } from '@react-spring/web';
import classNames from 'classnames';
import { getCurrencyIcon } from 'src/utils';
import { getNumberFormatter } from 'src/utils/getNumberFormatter';

export const AnimatedNumber = ({
  numToAnimate,
  className,
  currency
}: {
  numToAnimate: number;
  className?: string;
  currency: string | undefined;
}) => {
  const { number } = useSpring({
    from: { number: 0.0 },
    to: { number: numToAnimate },
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10, duration: 2000 }
  });

  const { numberFormatter } = getNumberFormatter();

  return (
    <div className={classNames('flex gap-2 justify-center', className ? { className } : '')}>
      {getCurrencyIcon(currency)}
      <animated.div>
        {number.to((n) => {
          return numberFormatter.format(n);
        })}
      </animated.div>
    </div>
  );
};
