import { twJoin, twMerge } from 'tailwind-merge';
import { FC, memo, useRef } from 'react';
import { useLanguageChange } from 'src/locale/hook/useLanguageChange';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useDropdown } from 'src/hooks/useDropdown';
import { Arrow } from '../svg';
import { useLanguageNavigate } from 'react-easyrocket';
import { LanguageDropDownList } from './components/LanguageDropDownList';
import { SupportedLanguages } from 'src/locale/types';

interface LanguagesSelectProps {
  containerClassname?: string;
}

export const LanguagesSelect: FC<LanguagesSelectProps> = memo(({ containerClassname }) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { language, handleChangeLanguage, currentLanguageIcon, currentLanguageName } =
    useLanguageChange();

  const { toggle, showList, close } = useDropdown({
    value: language
  });

  useClickOutside(dropdownRef, close);

  const setLanguageToUrl = useLanguageNavigate();

  const handleOpenClick = () => {
    if (!showList) {
      menuRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
    toggle();
  };

  const onChangeLanguage = (language: keyof SupportedLanguages) => {
    handleChangeLanguage(language);
    toggle();
    setLanguageToUrl(language);
  };

  return (
    <div
      className={twMerge(
        'max-w-[200px] w-[195px] 768:w-full p-[1px] rounded-[8px] my-[30px] z-20 cursor-pointer h-[180px] text-white',
        containerClassname
      )}
      ref={menuRef}>
      <div
        className="relative text-[14px] z-20 border border-button-secondary rounded-full w-full"
        ref={dropdownRef}>
        <button
          className="bg-[--background-main] flex relative flex-row items-center justify-between w-full h-full z-20 rounded-full px-[18px] py-[11px]"
          onClick={handleOpenClick}>
          <div className="flex gap-2.5">
            <div className="[&>svg]:w-6 [&>svg]:h-6">{currentLanguageIcon}</div>
            <p className="text-[16px] font-bold">{currentLanguageName}</p>
          </div>
          <Arrow
            className={twJoin('w-4 h-full stroke-[3px] transition-transform', showList && 'opened')}
          />
        </button>
        {showList && (
          <LanguageDropDownList
            currentLanguageName={currentLanguageName}
            onChangeLanguage={onChangeLanguage}
          />
        )}
      </div>
    </div>
  );
});

LanguagesSelect.displayName = 'LanguagesSelect';
