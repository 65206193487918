import googleIcon from 'src/assets/images/social-icons/google.png';
import microsoftIcon from 'src/assets/images/social-icons/microsoft.png';

type SocialIconsName = 'Google' | 'Microsoft';

type SocialIconProps = {
  source: string;
};

export const SOCIAL_ICONS: Record<SocialIconsName, SocialIconProps> = {
  Google: {
    source: googleIcon
  },
  Microsoft: {
    source: microsoftIcon
  }
};
