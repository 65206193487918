import { useMemo } from 'react';
import {
  MESSAGE_STATUS,
  useCentrifuge,
  useMessageContext,
  CentrifugalMessagesConfig
} from 'react-easyrocket';

type MessageStatus = 'info' | 'success' | 'failure';

const getMessageStatus = (status: MessageStatus): MESSAGE_STATUS => {
  if (status === 'failure') return MESSAGE_STATUS.ERROR;
  if (status === 'success') return MESSAGE_STATUS.SUCCESS;
  return MESSAGE_STATUS.INFO;
};

export const useCentrifugeMessages = () => {
  const { openMessage } = useMessageContext();

  const messagesConfig: CentrifugalMessagesConfig = useMemo(() => {
    return {
      USER_MESSAGE: (data) => {
        try {
          if (!data?.data?.messageType) return;
          if (!data?.data?.text) return;
          const status = data.data.messageType;
          const text = data.data.text;
          openMessage?.(`${text}`, getMessageStatus(status));
        } catch {
          console.error('Error');
        }
      }
    };
  }, [openMessage]);

  useCentrifuge({
    messagesConfig
  });
};
