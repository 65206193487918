import { memo, useMemo } from 'react';
import {
  makeFloat,
  useApiContext,
  useCurrency,
  useGetFullBalance,
  useGetFullInfo,
  useGetUserLevels
} from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import flash from 'src/assets/images/flash.webp';
import { getLevelsString } from 'src/utils';
import { PercentageScale } from '../PercentageScale/PercentageScale';
import { Separator } from '../Separator/Separator';

const SidebarUserInfo = () => {
  const { isAuthenticated } = useApiContext();
  const { fullInfo } = useGetFullInfo();
  const { t } = useTranslation();
  const { allLevels } = useGetUserLevels('loyalty');
  const { currencyName, currencyIcon } = useCurrency();
  const { fullBalance, bonusAccountCasino } = useGetFullBalance({ refetchInterval: 5000 });

  const userLevel = useMemo(() => {
    return fullInfo?.level ?? 1;
  }, [fullInfo]);

  const userPoints = useMemo(() => {
    return fullInfo?.points;
  }, [fullInfo]);

  const nextLevelInfo = useMemo(() => {
    return allLevels.find(({ levelInfo }) => {
      if (!userLevel) return undefined;
      return userLevel + 1 === levelInfo?.level;
    });
  }, [allLevels, userLevel]);

  const nextLevelPoints = useMemo(() => {
    return nextLevelInfo?.levelInfo?.fromPoints;
  }, [nextLevelInfo]);

  const levelPercentage = useMemo(() => {
    if (!userPoints || !nextLevelPoints) return undefined;
    return (userPoints / nextLevelPoints) * 100;
  }, [userPoints, nextLevelPoints]);

  const accountBalance = useMemo(() => {
    return makeFloat(fullBalance?.balance[currencyName]);
  }, [fullBalance, currencyName]);

  const accountBonusBalance = useMemo(() => {
    return makeFloat(bonusAccountCasino?.balance);
  }, [bonusAccountCasino?.balance]);

  if (!isAuthenticated) return null;

  return (
    <>
      {fullInfo?.level !== 0 && (
        <div className="grid gap-[15px] w-full text-center font-bold mt-[10px]">
          <div className="flex justify-center items-center text-[18px] text-game-badge-bonus">
            <img className="w-[30px] h-[30px] object-contain" src={flash} alt="flash" />
            <p>{`${t('general.level')} ${userLevel}`}</p>
          </div>

          <PercentageScale value={levelPercentage || 0} />

          <p className="text-[14px] text-text-gray">
            {nextLevelPoints &&
              userPoints &&
              getLevelsString(nextLevelPoints - userPoints - 1, userLevel + 1)}
          </p>
        </div>
      )}

      <Separator width="w-[100%]" noMarginTop />

      <div className="w-full font-bold grid gap-[10px]">
        <div className="w-full flex justify-between items-center">
          <p className="text-[18px]">{t('general.balance')}</p>
          <p className="text-[16px]">
            <span>{currencyIcon}</span> {accountBalance}
          </p>
        </div>
        <div className="w-full flex justify-between items-center">
          <p className="text-[18px]">{t('accountBonus.title')}</p>
          <p className="text-[16px]">
            <span>{currencyIcon}</span> {accountBonusBalance}
          </p>
        </div>
      </div>
    </>
  );
};

export default memo(SidebarUserInfo);
