import { useWindowSize } from 'react-easyrocket';
import { Deposit } from '../../pages/AccountWallet/Deposit';
import FullScreenModalWrapper from '../FullScreenModalWrapper';
import ModalWrapper from '../ModalWrapper';
import { DepositClose } from '../svg';
import { PropsWithChildren } from 'react';
import { useModalContext } from 'src/hooks/useModalContext';

export const DepositModal = () => {
  return (
    <Wrapper>
      <Deposit isModal />
    </Wrapper>
  );
};

function Wrapper({ children }: PropsWithChildren) {
  const { isMobileLayout } = useWindowSize();
  const { closeModal } = useModalContext();

  if (isMobileLayout) {
    return (
      <FullScreenModalWrapper className="bg-gradient">
        <DepositClose
          className="absolute top-0 right-0 cursor-pointer z-[20] box-content pt-4 px-3"
          onClick={closeModal}
        />
        {children}
      </FullScreenModalWrapper>
    );
  }
  return (
    <ModalWrapper containerClassName="bg-gradient max-w-[900px]" className="min-w-[750px]">
      {children}
    </ModalWrapper>
  );
}
