import { useEffect } from 'react';

export const useFrameMessages = (iframe: HTMLIFrameElement | null) => {
  useEffect(() => {
    const handleSportFrameMessage = (event: any) => {
      console.log('frame_message', event?.data);
    };
    if (iframe) {
      iframe.addEventListener('message', handleSportFrameMessage);
    }
    return () => {
      if (iframe) {
        iframe.removeEventListener('message', handleSportFrameMessage);
      }
    };
  }, []);
};
