export const isDev = import.meta.env.MODE === 'development';

export const API_URL = isDev ? import.meta.env.VITE_DOMAIN : window.origin;
export const CONTENT_URL = isDev ? import.meta.env.VITE_DOMAIN : '';

/** ( !devDomain && !localhostDomain ) || prodEnv */
export const isProductionEnv =
  (!window.origin.includes('froach') && !window.origin.includes('localhost')) ||
  import.meta.env.VITE_DOMAIN?.includes('jacktop');

export const isDevEnv = window.origin.includes('froach');
