import { useWindowSize } from 'react-easyrocket';
import { twJoin } from 'tailwind-merge';
import { CookiesBanner } from './CookiesBanner';
import { useLocation } from 'react-router-dom';

const STICK_TO_BOTTOM_PAGE = ['game/', 'welcome-bonus', 'account/'];

export function Cookies() {
  const { isDesktopLayout } = useWindowSize();
  const { pathname } = useLocation();

  const stickToBottom =
    STICK_TO_BOTTOM_PAGE.some((route) => pathname.includes(route)) || isDesktopLayout;

  return (
    <div
      className={twJoin('fixed z-[100] w-screen ', stickToBottom ? 'bottom-0' : 'bottom-[87px]')}>
      <CookiesBanner />
    </div>
  );
}

export default Cookies;
