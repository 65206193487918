import { CheckEventDatesParams, checkEventDatesFromEnv } from 'src/utils/checkEventDates';

const END_OF_OLYMPIC_DATE = {
  year: '2024',
  month: '08',
  day: '11'
};

export const OLYMPIC_LOGO_DATES_PROD: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '07',
    day: '24'
  },
  endDate: END_OF_OLYMPIC_DATE
};

export const OLYMPIC_LOGO_DATES_DEV: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '07',
    day: '24'
  },
  endDate: END_OF_OLYMPIC_DATE
};

export const OLYMPIC_CATEGORY_DATES_DEV: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '07',
    day: '24'
  },
  endDate: END_OF_OLYMPIC_DATE
};

export const OLYMPIC_CATEGORY_DATES_PROD: CheckEventDatesParams = {
  startDate: {
    year: '2024',
    month: '07',
    day: '24'
  },
  endDate: END_OF_OLYMPIC_DATE
};

export const shouldShowEuroLogo = checkEventDatesFromEnv({
  devDates: OLYMPIC_LOGO_DATES_DEV,
  prodDates: OLYMPIC_LOGO_DATES_PROD
});

export const shouldShowOlympicLogo = checkEventDatesFromEnv({
  devDates: OLYMPIC_LOGO_DATES_DEV,
  prodDates: OLYMPIC_LOGO_DATES_PROD
});

export const shouldShowOlympicCategory = checkEventDatesFromEnv({
  devDates: OLYMPIC_CATEGORY_DATES_DEV,
  prodDates: OLYMPIC_CATEGORY_DATES_PROD
});
